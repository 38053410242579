// @use 'sass:map';
@use '../../../../styles/tokens';
@use '../../../../styles';

.NavigationDrawer{

    --lms-navigation-drawer-container-color: var(--md-sys-color-surface);
    --lms-navigation-drawer-container-height: 100%;
    --lms-navigation-drawer-container-left-right-padding: 12px;
    --lms-navigation-drawer-container-shape: var(--md-sys-shape-corner-large-end);
    --lms-navigation-drawer-container-width: 360px;
    

    @include styles.lms-component-styles-container();

    border-radius: var(--lms-navigation-drawer-container-shape);
    height: var(--lms-navigation-drawer-container-height);
    width: var(--lms-navigation-drawer-container-width);

}

.NavigationDrawer-background{
    @include styles.lms-component-styles-background();
    
    background-color: var(--lms-navigation-drawer-container-color);
}

.NavigationDrawer-content{
    @include styles.lms-component-styles-content();
    @include styles.lms-flex-container-column();
    // @include styles.lms-flex-container-gap(0, #{map-get($lms-top-app-bar, 'padding-between-elements')});
    @include styles.lms-flex-item-auto();

    padding: 16px var(--lms-navigation-drawer-container-left-right-padding);    

    // &>*{
    //     @include styles.lms-flex-container-column('left');
    // }
}

// @use '../../../../styles' as styles;

// .lms-navigation-drawer{

//     $lms-comp-navigation-drawer: styles.lms-comp-navigation-drawer-values();

//     &.lms-navigation-drawer_container{
//         @include styles.lms-component-styles-container();

//         background-color: map-get($lms-comp-navigation-drawer, 'container-color');
//         border-radius: map-get($lms-comp-navigation-drawer, 'container-shape');
//         height: 100%;
//         width: map-get($lms-comp-navigation-drawer, 'container-width');

//     }

//     .lms-navigation-drawer_elevation{
//         // @include styles.lms-elevation-styles-shared();
//         // @include styles.lms-elevation-styles-level(map-get($lms-comp-navigation-drawer, 'container-elevation'));
//     }

//     .lms-navigation-drawer_content{
//         @include styles.lms-component-styles-content();

//         ul{
//             @include styles.lms-flex-container-column();
//             margin: 0;
//             padding: 0 map-get($lms-comp-navigation-drawer, 'left-right-padding');
//         }
//     }

//     .lms-navigation-drawer_item{
//         @include styles.lms-component-styles-container();
        
//         border-radius: map-get($lms-comp-navigation-drawer, 'active-indicator-shape');
//         height: map-get($lms-comp-navigation-drawer, 'active-indicator-height');
//         list-style: none;
//         width: 100%;
        
//         background-color: red;
//         .lms-navigation-drawer_item_active-indicator{
//             @include styles.lms-component-styles-state();


//         }

//         .lms-navigation-drawer_item_content{
//             @include styles.lms-component-styles-content();
//             @include styles.lms-flex-container-row();
//             @include styles.lms-flex-container-gap(0, map-get($lms-comp-navigation-drawer, 'padding-between-item-elements'));
//             @include styles.lms-flex-container-align-center();
//         }

//     }

//     // .lms-navigation-drawer_active-indicator{
//     //     @include styles.lms-flex-container-column();

//     //     // background-color: map-get($lms-comp-navigation-drawer, 'active-indicator-color');
//     //     border-radius: map-get($lms-comp-navigation-drawer, 'active-indicator-shape');
//     //     height: map-get($lms-comp-navigation-drawer, 'active-indicator-height');
//     //     list-style: none;

//     //     &>a{
//     //         @include styles.lms-flex-container-row();
//     //         @include styles.lms-flex-container-gap(0, map-get($lms-comp-navigation-drawer, 'padding-between-item-elements'));
//     //         @include styles.lms-flex-container-align-center();
//     //         @include styles.lms-flex-item-auto();

//     //         padding-left: map-get($lms-comp-navigation-drawer, 'active-indicator-left-padding');
//     //         text-decoration: none;
//     //     }
//     // }

//     // .lms-navigation-drawer_label-text{
//     //     @include styles.lms-flex-item-auto();
//     //     @include styles.lms-typography-font(map-get($lms-comp-navigation-drawer, 'label-text-font'));

//     //     color:  map-get($lms-comp-navigation-drawer, 'label-text-color');

//     // }

//     // .lms-navigation-drawer_icon{
//     //     color:  map-get($lms-comp-navigation-drawer, 'icon-color');
//     // }

//     // // State - Hovered
//     // .lms-navigation-drawer_active-indicator:hover{

//     // }

// } 