@use '../../../../../styles';

.accountSelfCreationConfigurationNoButton{
     @include styles.lms-flex-item-auto();
     @include styles.lms-flex-container-row();
     @include styles.lms-flex-container-gap(8, 0);      
     @include styles.lms-flex-container-align-center();  
     height: 48px;
}
.accountSelfCreationConfigurationNoButton-label{
     @include styles.lms-flex-item-none();
     color: var(--md-sys-color-on-surface-variant);
     width:160px;
}
.accountSelfCreationConfigurationNoButton-value{
     @include styles.lms-flex-item-auto();
     color: var(--md-sys-color-on-surface);
     padding-left: 16px;
}