@use '../../../styles';

.Components{
    @include styles.lms-flex-container-column();
    @include styles.lms-flex-container-gap(8, 0);

    
}

.Components-section{
    @include styles.lms-flex-container-column();
    @include styles.lms-flex-container-gap(8, 0);
}

.Components-sectionBody{
    @include styles.lms-flex-container-column();
    @include styles.lms-flex-container-gap(8, 0);

    padding: 8px 0;
}

.Components-sectionBodyRow{
    @include styles.lms-flex-container-row();
    @include styles.lms-flex-container-gap(0, 8);
}

.Components-image{
    width: 300px;
}