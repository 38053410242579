@use '../../../../styles';

.Snackbar-container{
    z-index: 9999;
    position: fixed;
    bottom: 16px;
    width: 100%;
    animation: fadeIn 0.5s;

//     animation: 0.5s fadeIn 2.5s, 0.5s fadeOut ;
//     -webkit-animation: fadeIn 0.5s , fadeOut 0.5s;
//     -moz-animation: fadeIn 0.5s , fadeOut 0.5s;
//     -o-animation: fadeIn 0.5s , fadeOut 0.5s;
//     -ms-animation: fadeIn 0.5s , fadeOut 0.5s;
}

.Snackbar{
    --md-snackbar-container-color: var(--md-sys-color-inverse-surface);
    --md-snackbar-container-left-padding: 16px;
    --md-snackbar-container-shape: var(--md-sys-shape-corner-extra-small);
    --md-snackbar-container-single-line-height: 48px;
    --md-snackbar-container-right-padding: 8px;
    --md-snackbar-focus-state-layer-color: var(--md-sys-color-inverse-primary);
    --md-snackbar-focus-state-layer-opacity: var(--md-sys-state-focus-state-layer-opacity);
    --md-snackbar-hover-state-layer-color: var(--md-sys-color-inverse-primary);
    --md-snackbar-hover-state-layer-opacity: var(--md-sys-state-hover-state-layer-opacity);
    --md-snackbar-label-text-color: var(--md-sys-color-inverse-primary);
    --md-snackbar-pressed-state-layer-color: var(--md-sys-color-inverse-primary);
    --md-snackbar-pressed-state-layer-opacity: var(--md-sys-state-pressed-state-layer-opacity);
    --md-snackbar-supporting-text-color: var(--md-sys-color-inverse-on-surface);


    
    @include styles.lms-component-styles-container();

    border-radius: var(--md-snackbar-container-shape);
    max-width: 600px;
    overflow: hidden;
    padding-left: var(--md-snackbar-container-left-padding);
    padding-right: var(--md-snackbar-container-right-padding);

    md-elevation{
        @include styles.lms-component-styles-elevation($elevation: 'level3');
    }

}

.Snackbar--singleLine{
    height: var(--md-snackbar-container-single-line-height);
}

.Snackbar-background,
.Snackbar-stateLayer{
    @include styles.lms-component-styles-background();
}

.Snackbar-background{
    background-color: var(--md-snackbar-container-color);
}

.Snackbar-content{
    @include styles.lms-component-styles-content();
    @include styles.lms-flex-container-row('left');
}

.Snackbar-action{

    md-text-button{
        --md-text-button-label-text-color: var(--md-snackbar-label-text-color);
        --md-text-button-hover-label-text-color: var(--md-snackbar-label-text-color);
        --md-text-button-hover-state-layer-color: var(--md-snackbar-hover-state-layer-color);
        --md-text-button-hover-state-layer-opacity: var(--md-snackbar-hover-state-layer-opacity);
        --md-text-button-focus-label-text-color: var(--md-snackbar-label-text-color);
        --md-text-button-focus-state-layer-color: var(--md-snackbar-focus-state-layer-color);
        --md-text-button-focus-state-layer-opacity: var(--md-snackbar-focus-state-layer-opacity);
        --md-text-button-pressed-label-text-color: var(--md-snackbar-label-text-color);
        --md-text-button-pressed-state-layer-color: var(--md-snackbar-pressed-state-layer-color);
        --md-text-button-pressed-state-layer-opacity: var(--md-snackbar-pressed-state-layer-opacity);
    }
}

.Snackbar-supportingText{
    @include styles.lms-flex-item-auto();

    color: var(--md-snackbar-supporting-text-color);
}

.Snackbar-action-stateLayer{
    @include styles.lms-component-styles-background();
}

.Snackbar-action-content{
    @include styles.lms-component-styles-content();
    @include styles.lms-flex-container-column('center');
}
  
@keyframes fadeIn {
    0% {
      bottom: 0;
      opacity: 0;
    }
    100% {
      bottom: 16px;
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    100% {
      bottom: 0;
      opacity: 0;
    }
    0% {
      bottom: 16px;
      opacity: 1;
    }
  }

  
  
//   @keyframes fadeIn {
//     0% { opacity: 0; }
//     100% { opacity: 1; }
//   }
  
//   @-moz-keyframes fadeIn {
//     0% { opacity: 0; }
//     100% { opacity: 1; }
//   }
  
//   @-webkit-keyframes fadeIn {
//     0% { opacity: 0; }
//     100% { opacity: 1; }
//   }
  
//   @-o-keyframes fadeIn {
//     0% { opacity: 0; }
//     100% { opacity: 1; }
//   }
  
//   @-ms-keyframes fadeIn {
//     0% { opacity: 0; }
//     100% { opacity: 1; }
//   }

//   @keyframes fadeOut {
//     0% { opacity: 1; }
//     100% { opacity: 0; }
//   }
  
//   @-moz-keyframes fadeOut {
//     0% { opacity: 1; }
//     100% { opacity: 0; }
//   }
  
//   @-webkit-keyframes fadeOut {
//     0% { opacity: 1; }
//     100% { opacity: 0; }
//   }
  
//   @-o-keyframes fadeOut {
//     0% { opacity: 1; }
//     100% { opacity: 0; }
//   }
  
//   @-ms-keyframes fadeOut {
//     0% { opacity: 1; }
//     100% { opacity: 0; }
//   }