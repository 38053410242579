@use 'sass:map';
@use 'sass:list';
@use '../../../../styles/tokens';
@use '../../../../styles';

$lms-filled-text-field: tokens.lms-comp-filled-text-field-values();

.FilledTextField-container{
    @include styles.lms-flex-container-column();

    
    --md-filled-text-field-active-indicator-color: #{map.get($lms-filled-text-field, 'active-indicator-color')};
    --md-filled-text-field-active-indicator-height: #{map.get($lms-filled-text-field, 'active-indicator-height')};
    --md-filled-text-field-caret-color: #{map.get($lms-filled-text-field, 'caret-color')};
    --md-filled-text-field-container-color: #{map.get($lms-filled-text-field, 'container-color')};
    --md-filled-text-field-container-shape: #{map.get($lms-filled-text-field, 'container-shape')};
    --md-filled-text-field-container-shape-start-start: #{list.nth(map.get($lms-filled-text-field, 'container-shape'), 1)};
    --md-filled-text-field-container-shape-start-end: #{list.nth(map.get($lms-filled-text-field, 'container-shape'), 2)};
    --md-filled-text-field-container-shape-end-end: #{list.nth(map.get($lms-filled-text-field, 'container-shape'), 3)};
    --md-filled-text-field-container-shape-end-start: #{list.nth(map.get($lms-filled-text-field, 'container-shape'), 4)};
    --md-filled-text-field-disabled-active-indicator-color: #{map.get($lms-filled-text-field, 'disabled-active-indicator-color')};
    --md-filled-text-field-disabled-active-indicator-height: #{map.get($lms-filled-text-field, 'disabled-active-indicator-height')};
    --md-filled-text-field-disabled-active-indicator-opacity: #{map.get($lms-filled-text-field, 'disabled-active-indicator-opacity')};
    --md-filled-text-field-disabled-container-color: #{map.get($lms-filled-text-field, 'disabled-container-color')};
    --md-filled-text-field-disabled-container-opacity: #{map.get($lms-filled-text-field, 'disabled-container-opacity')};
    --md-filled-text-field-disabled-input-text-color: #{map.get($lms-filled-text-field, 'disabled-input-text-color')};
    --md-filled-text-field-disabled-input-text-opacity: #{map.get($lms-filled-text-field, 'disabled-input-text-opacity')};
    --md-filled-text-field-disabled-label-text-color: #{map.get($lms-filled-text-field, 'disabled-label-text-color')};
    --md-filled-text-field-disabled-label-text-opacity: #{map.get($lms-filled-text-field, 'disabled-label-text-opacity')};
    --md-filled-text-field-disabled-leading-icon-color: #{map.get($lms-filled-text-field, 'disabled-leading-icon-color')};
    --md-filled-text-field-disabled-leading-icon-opacity: #{map.get($lms-filled-text-field, 'disabled-leading-icon-opacity')};
    --md-filled-text-field-disabled-supporting-text-color: #{map.get($lms-filled-text-field, 'disabled-supporting-text-color')};
    --md-filled-text-field-disabled-supporting-text-opacity: #{map.get($lms-filled-text-field, 'disabled-supporting-text-opacity')};
    --md-filled-text-field-disabled-trailing-icon-color: #{map.get($lms-filled-text-field, 'disabled-trailing-icon-color')};
    --md-filled-text-field-disabled-trailing-icon-opacity: #{map.get($lms-filled-text-field, 'disabled-trailing-icon-opacity')};
    --md-filled-text-field-error-active-indicator-color: #{map.get($lms-filled-text-field, 'error-active-indicator-color')};
    --md-filled-text-field-error-focus-active-indicator-color: #{map.get($lms-filled-text-field, 'error-focus-active-indicator-color')};
    --md-filled-text-field-error-focus-caret-color: #{map.get($lms-filled-text-field, 'error-focus-caret-color')};
    --md-filled-text-field-error-focus-input-text-color: #{map.get($lms-filled-text-field, 'error-focus-input-text-color')};
    --md-filled-text-field-error-focus-label-text-color: #{map.get($lms-filled-text-field, 'error-focus-label-text-color')};
    --md-filled-text-field-error-focus-leading-icon-color: #{map.get($lms-filled-text-field, 'error-focus-leading-icon-color')};
    --md-filled-text-field-error-focus-supporting-text-color: #{map.get($lms-filled-text-field, 'error-focus-supporting-text-color')};
    --md-filled-text-field-error-focus-trailing-icon-color: #{map.get($lms-filled-text-field, 'error-focus-trailing-icon-color')};
    --md-filled-text-field-error-hover-active-indicator-color: #{map.get($lms-filled-text-field, 'error-hover-active-indicator-color')};
    --md-filled-text-field-error-hover-input-text-color: #{map.get($lms-filled-text-field, 'error-hover-input-text-color')};
    --md-filled-text-field-error-hover-label-text-color: #{map.get($lms-filled-text-field, 'error-hover-label-text-color')};
    --md-filled-text-field-error-hover-leading-icon-color: #{map.get($lms-filled-text-field, 'error-hover-leading-icon-color')};
    --md-filled-text-field-error-hover-state-layer-color: #{map.get($lms-filled-text-field, 'error-hover-state-layer-color')};
    --md-filled-text-field-error-hover-state-layer-opacity: #{map.get($lms-filled-text-field, 'error-hover-state-layer-opacity')};
    --md-filled-text-field-error-hover-supporting-text-color: #{map.get($lms-filled-text-field, 'error-hover-supporting-text-color')};
    --md-filled-text-field-error-hover-trailing-icon-color: #{map.get($lms-filled-text-field, 'error-hover-trailing-icon-color')};
    --md-filled-text-field-error-input-text-color: #{map.get($lms-filled-text-field, 'error-input-text-color')};
    --md-filled-text-field-error-label-text-color: #{map.get($lms-filled-text-field, 'error-label-text-color')};
    --md-filled-text-field-error-leading-icon-color: #{map.get($lms-filled-text-field, 'error-leading-icon-color')};
    --md-filled-text-field-error-supporting-text-color: #{map.get($lms-filled-text-field, 'error-supporting-text-color')};
    --md-filled-text-field-error-trailing-icon-color: #{map.get($lms-filled-text-field, 'error-trailing-icon-color')};
    --md-filled-text-field-focus-active-indicator-color: #{map.get($lms-filled-text-field, 'focus-active-indicator-color')};
    --md-filled-text-field-focus-active-indicator-height: #{map.get($lms-filled-text-field, 'focus-active-indicator-height')};
    --md-filled-text-field-focus-input-text-color: #{map.get($lms-filled-text-field, 'focus-input-text-color')};
    --md-filled-text-field-focus-label-text-color: #{map.get($lms-filled-text-field, 'focus-label-text-color')};
    --md-filled-text-field-focus-leading-icon-color: #{map.get($lms-filled-text-field, 'focus-leading-icon-color')};
    --md-filled-text-field-focus-supporting-text-color: #{map.get($lms-filled-text-field, 'focus-supporting-text-color')};
    --md-filled-text-field-focus-trailing-icon-color: #{map.get($lms-filled-text-field, 'focus-trailing-icon-color')};
    --md-filled-text-field-hover-active-indicator-color: #{map.get($lms-filled-text-field, 'hover-active-indicator-color')};
    --md-filled-text-field-hover-active-indicator-height: #{map.get($lms-filled-text-field, 'hover-active-indicator-height')};
    --md-filled-text-field-hover-input-text-color: #{map.get($lms-filled-text-field, 'hover-input-text-color')};
    --md-filled-text-field-hover-label-text-color: #{map.get($lms-filled-text-field, 'hover-label-text-color')};
    --md-filled-text-field-hover-leading-icon-color: #{map.get($lms-filled-text-field, 'hover-leading-icon-color')};
    --md-filled-text-field-hover-state-layer-color: #{map.get($lms-filled-text-field, 'hover-state-layer-color')};
    --md-filled-text-field-hover-state-layer-opacity: #{map.get($lms-filled-text-field, 'hover-state-layer-opacity')};
    --md-filled-text-field-hover-supporting-text-color: #{map.get($lms-filled-text-field, 'hover-supporting-text-color')};
    --md-filled-text-field-hover-trailing-icon-color: #{map.get($lms-filled-text-field, 'hover-trailing-icon-color')};
    --md-filled-text-field-input-text-color: #{map.get($lms-filled-text-field, 'input-text-color')};
    --md-filled-text-field-input-text-font: #{map.get($lms-filled-text-field, 'input-text-font')};
    --md-filled-text-field-input-text-line-height: #{map.get($lms-filled-text-field, 'input-text-line-height')};
    --md-filled-text-field-input-text-placeholder-color: #{map.get($lms-filled-text-field, 'input-text-placeholder-color')};
    --md-filled-text-field-input-text-prefix-color: #{map.get($lms-filled-text-field, 'input-text-prefix-color')};
    --md-filled-text-field-input-text-size: #{map.get($lms-filled-text-field, 'input-text-size')};
    --md-filled-text-field-input-text-suffix-color: #{map.get($lms-filled-text-field, 'input-text-suffix-color')};
    --md-filled-text-field-input-text-tracking: #{map.get($lms-filled-text-field, 'input-text-tracking')};
    --md-filled-text-field-input-text-weight:  #{map.get($lms-filled-text-field, 'input-text-weight')};
    --md-filled-text-field-label-text-color:  #{map.get($lms-filled-text-field, 'label-text-color')};
    --md-filled-text-field-label-text-font:  #{map.get($lms-filled-text-field, 'label-text-font')};
    --md-filled-text-field-label-text-line-height:  #{map.get($lms-filled-text-field, 'label-text-line-height')};
    --md-filled-text-field-label-text-populated-line-height:  #{map.get($lms-filled-text-field, 'label-text-populated-line-height')};
    --md-filled-text-field-label-text-populated-size:  #{map.get($lms-filled-text-field, 'label-text-populated-size')};
    --md-filled-text-field-label-text-size:  #{map.get($lms-filled-text-field, 'label-text-size')};
    --md-filled-text-field-label-text-tracking:  #{map.get($lms-filled-text-field, 'label-text-tracking')};
    --md-filled-text-field-label-text-weight:  #{map.get($lms-filled-text-field, 'label-text-weight')};
    --md-filled-text-field-leading-icon-color:  #{map.get($lms-filled-text-field, 'leading-icon-color')};
    --md-filled-text-field-leading-icon-size:  #{map.get($lms-filled-text-field, 'leading-icon-size')};
    --md-filled-text-field-supporting-text-color:  #{map.get($lms-filled-text-field, 'supporting-text-color')};
    --md-filled-text-field-supporting-text-font:  #{map.get($lms-filled-text-field, 'supporting-text-font')};
    --md-filled-text-field-supporting-text-line-height:  #{map.get($lms-filled-text-field, 'supporting-text-line-height')};
    --md-filled-text-field-supporting-text-size:  #{map.get($lms-filled-text-field, 'supporting-text-size')};
    --md-filled-text-field-supporting-text-tracking:  #{map.get($lms-filled-text-field, 'supporting-text-tracking')};
    --md-filled-text-field-supporting-text-weight:  #{map.get($lms-filled-text-field, 'supporting-text-weight')};
    --md-filled-text-field-trailing-icon-color:  #{map.get($lms-filled-text-field, 'trailing-icon-color')};
    --md-filled-text-field-trailing-icon-size:  #{map.get($lms-filled-text-field, 'trailing-icon-size')};
    --md-filled-text-field-label-bottom-padding: 4px;

    &> label{
        color: var(--md-filled-text-field-label-text-color);
        @include styles.lms-typescale-font('label-small');
        
        padding-left: 8px;
        padding-bottom: var(--md-filled-text-field-label-bottom-padding);
    }

}

// .FilledTextField{

//     // .FilledTextField-errorTrailingIcon{
//     //    display: none;
//     // }

//     // &.is-invalid .FilledTextField-errorTrailingIcon{
//     //     color: var(--md-filled-text-field-error-trailing-icon-color);
//     //     display: inherit;
//     // }
// }