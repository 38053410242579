@use '../flex/flex.scss' as flex;

@use '@material/web/elevation/elevation';

@mixin styles-container {
    @include flex.container-column();
    @include flex.container-column-fill();
    
    box-sizing: border-box;
    position: relative;
    // Reset z-index for background elements.
    z-index: 0;
}

@mixin _styles-state-layer {
    border-radius: inherit;
    inset: 0;
    pointer-events: none;
    position: absolute;
}

@mixin styles-background {
    @include _styles-state-layer();

    // Place behind content.
    z-index: -1;
}

@mixin styles-outline {
    @include _styles-state-layer();

    // A default transparent border that is visible in HCM
    border: 1px solid transparent;
    // Outline should be placed on top of content, such as images.
    z-index: 1;
}

@mixin styles-elevation($elevation: 'level0', $shadow-color: var(--md-sys-color-shadow)) {
    @include _styles-state-layer();

    // Place behind content.
    z-index: -1;

    @include elevation.theme(
        (
            'level': $elevation,
            'shadow-color': $shadow-color,
        )
    );
}


@mixin styles-content {
    @include flex.item-auto();
    
    border-radius: inherit;
}