@use '../tokens';

@mixin theme{

    $light-tokens: tokens.lms-sys-color-values-light();
    $dark-tokens: tokens.lms-sys-color-values-dark();
    
    @media (prefers-color-scheme: light) {

        @each $token, $value in $light-tokens {

            @if $value {
                --md-sys-color-#{$token}: #{$value};
            }
    
        }

    }

    @media (prefers-color-scheme: dark) {

        @each $token, $value in $dark-tokens {

            @if $value {
                --md-sys-color-#{$token}: #{$value};
            }
    
        }

    }
    
}