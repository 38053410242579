@use 'sass:map';
@use '../../../../styles/tokens';

$lms-icon: tokens.lms-comp-icon-values();

.Icon{
    
    --md-icon-font: #{map.get($lms-icon, 'font')};
    --md-icon-size: #{map.get($lms-icon, 'size')};

}
  
.Icon--size18{
    --md-icon-size: 18px;      
}
.Icon--size20{
    --md-icon-size: 20px;      
}
.Icon--size24{
    --md-icon-size: 24px;      
}
.Icon--size40{
    --md-icon-size: 40px;      
}
.Icon--size48{
    --md-icon-size: 48px;      
}

.is-filled{
    font-variation-settings: 'FILL' 1;
}
