@use '../../../../styles' as styles;

.SettingsDetailPane {
    --lms-settings-detail-pane-container-color: var(--md-sys-color-surface-container-low);
}

@include styles.lms-layout-compact(){
    
    .SettingsDetailPane {
        --lms-settings-detail-pane-container-color: var(--md-sys-color-background);
    }

}

@include styles.lms-layout-medium(){

    .SettingsDetailPane {      
        --lms-settings-detail-pane-container-color: var(--md-sys-color-background);
    }

}

.SettingsDetailPane{ 
    @include styles.lms-flex-item-auto();
    @include styles.lms-flex-container-column();
    @include styles.lms-flex-container-gap(8, 0); 
    
    background-color: var(--lms-settings-detail-pane-container-color);
    border-radius: var(--md-sys-shape-corner-medium);
}

.SettingsDetailPane-header{
    @include styles.lms-flex-container-row($align: left);
    @include styles.lms-flex-container-gap(0, 8); 

    padding: 8px;

}

.SettingsDetailPane-headerText{
    @include styles.lms-typography-font('title-large');

    color: var(--md-sys-color-on-surface);
}

