@use '../../../../styles';

.FilledSelect-container{
    @include styles.lms-flex-container-column();

    position: relative;
    
    &> label{
        color: var(--md-sys-color-on-surface-variant);
        
        padding-left: 8px;
        padding-bottom: 4px;
    }
}