@use '../../../styles';

.InfrastructureTests-section{
    @include styles.lms-flex-container-column();
    @include styles.lms-flex-container-gap(16, 16);

    form {
        @include styles.lms-flex-container-column();
        @include styles.lms-flex-container-gap(16, 16);
    }
}