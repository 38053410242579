@use '../../../../styles';

.NavigationDrawerItem{

    --lms-navigation-drawer-active-icon-color: var(--md-sys-color-on-secondary-container);
    --lms-navigation-drawer-active-icon-size: 24px;
    --lms-navigation-drawer-active-indicator-color: var(--md-sys-color-secondary-container);
    --lms-navigation-drawer-active-indicator-height: 56px;
    --lms-navigation-drawer-active-indicator-padding: 16px;
    --lms-navigation-drawer-active-indicator-shape: var(--md-sys-shape-corner-full);
    --lms-navigation-drawer-active-indicator-width: 100%;
    --lms-navigation-drawer-active-focus-icon-color: var(--md-sys-color-on-secondary-container);
    --lms-navigation-drawer-active-focus-label-text-color: var(--md-sys-color-on-secondary-container);
    --lms-navigation-drawer-active-focus-state-layer-color: var(--md-sys-color-on-secondary-container);
    --lms-navigation-drawer-active-hover-icon-color: var(--md-sys-color-on-secondary-container);
    --lms-navigation-drawer-active-hover-label-text-color: var(--md-sys-color-on-secondary-container);
    --lms-navigation-drawer-active-hover-state-layer-color: var(--md-sys-color-on-secondary-container);
    --lms-navigation-drawer-active-label-text-color: var(--md-sys-color-on-secondary-container);
    --lms-navigation-drawer-active-pressed-icon-color: var(--md-sys-color-on-secondary-container);
    --lms-navigation-drawer-active-pressed-label-text-color: var(--md-sys-color-on-secondary-container);
    --lms-navigation-drawer-active-pressed-state-layer-color: var(--md-sys-color-on-secondary-container);
    --lms-navigation-drawer-focus-indicator-color: var(--md-sys-color-secondary);
    --lms-navigation-drawer-focus-indicator-thickness: var(--md-sys-state-focus-indicator-thickness);
    --lms-navigation-drawer-focus-state-layer-opacity: var(--md-sys-state-focus-state-layer-opacity);
    --lms-navigation-drawer-hover-state-layer-opacity: var(--md-sys-state-hover-state-layer-opacity);
    --lms-navigation-drawer-pressed-state-layer-opacity: var(--md-sys-state-pressed-state-layer-opacity);
    --lms-navigation-drawer-inactive-icon-color: var(--md-sys-color-on-surface-variant);
    --lms-navigation-drawer-inactive-focus-icon-color: var(--md-sys-color-on-surface);
    --lms-navigation-drawer-inactive-focus-label-text-color: var(--md-sys-color-on-surface);
    --lms-navigation-drawer-inactive-focus-state-layer-color: var(--md-sys-color-on-surface);
    --lms-navigation-drawer-inactive-hover-icon-color: var(--md-sys-color-on-surface);
    --lms-navigation-drawer-inactive-hover-label-text-color: var(--md-sys-color-on-surface);
    --lms-navigation-drawer-inactive-hover-state-layer-color: var(--md-sys-color-on-surface);
    --lms-navigation-drawer-inactive-label-text-color: var(--md-sys-color-on-surface-variant);
    --lms-navigation-drawer-inactive-pressed-icon-color: var(--md-sys-color-on-surface);
    --lms-navigation-drawer-inactive-pressed-label-text-color: var(--md-sys-color-on-surface);
    --lms-navigation-drawer-inactive-pressed-state-layer-color: var(--md-sys-color-on-secondary-container);
    
    @include styles.lms-component-styles-container();

    border-radius: var(--lms-navigation-drawer-active-indicator-shape);
    height: var(--lms-navigation-drawer-active-indicator-height);
    width: var(--lms-navigation-drawer-active-indicator-width);

    md-icon{
        color: var(--lms-navigation-drawer-inactive-icon-color);
    }

    .NavigationDrawerItem-labelText{
        @include styles.lms-typescale-font('label-large');

        color: var(--lms-navigation-drawer-inactive-label-text-color);
    }

    &:focus-within {

        md-icon{
            color: var(--lms-navigation-drawer-inactive-focus-icon-color);
        }

        .NavigationDrawerItem-labelText{
            color: var(--lms-navigation-drawer-inactive-focus-label-text-color);
        }

        .NavigationDrawerItem-stateLayer{
            background-color: var(--lms-navigation-drawer-inactive-focus-state-layer-color);
            opacity: var(--lms-navigation-drawer-focus-state-layer-opacity);
        }
        
        .NavigationDrawerItem-outline{
            border-color: var(--lms-navigation-drawer-focus-indicator-color);
        }
    }

    &:hover {

        md-icon{
            color: var(--lms-navigation-drawer-inactive-hover-icon-color);
        }

        .NavigationDrawerItem-labelText{
            color: var(--lms-navigation-drawer-inactive-hover-label-text-color);
        }

        .NavigationDrawerItem-stateLayer{
            background-color: var(--lms-navigation-drawer-inactive-hover-state-layer-color);
            opacity: var(--lms-navigation-drawer-hover-state-layer-opacity);
        }

    }


    &:active {

        md-icon{
            color: var(--lms-navigation-drawer-inactive-pressed-icon-color);
        }

        .NavigationDrawerItem-labelText{
            color: var(--lms-navigation-drawer-inactive-pressed-label-text-color);
        }

        .NavigationDrawerItem-stateLayer{
            background-color: var(--lms-navigation-drawer-inactive-pressed-state-layer-color);
            opacity: var(--lms-navigation-drawer-pressed-state-layer-opacity);
        }

    }

    &.is-active {

        md-icon{
            color: var(--lms-navigation-drawer-active-icon-color);
        }

        .NavigationDrawerItem-labelText{
            color: var(--lms-navigation-drawer-active-label-text-color);
        }

        .NavigationDrawerItem-background{
            background-color: var(--lms-navigation-drawer-active-indicator-color);
        }

        &:focus-within {

            md-icon{
                color: var(--lms-navigation-drawer-active-focus-icon-color);
            }
    
            .NavigationDrawerItem-labelText{
                color: var(--lms-navigation-drawer-active-focus-label-text-color);
            }
    
            .NavigationDrawerItem-stateLayer{
                background-color: var(--lms-navigation-drawer-active-focus-state-layer-color);
            }
            
        }

        &:hover {

            md-icon{
                color: var(--lms-navigation-drawer-active-hover-icon-color);
            }
    
            .NavigationDrawerItem-labelText{
                color: var(--lms-navigation-drawer-active-hover-label-text-color);
            }
    
            .NavigationDrawerItem-stateLayer{
                background-color: var(--lms-navigation-drawer-active-hover-state-layer-color);
            }
    
        }

        
        &:active {

            md-icon{
                color: var(--lms-navigation-drawer-active-pressed-icon-color);
            }
    
            .NavigationDrawerItem-labelText{
                color: var(--lms-navigation-drawer-active-pressed-label-text-color);
            }
    
            .NavigationDrawerItem-stateLayer{
                background-color: var(--lms-navigation-drawer-active-pressed-state-layer-color);
            }
    
        }
    }

}

.NavigationDrawerItem-stateLayer,
.NavigationDrawerItem-background{
    @include styles.lms-component-styles-background();
    
}

.NavigationDrawerItem-outline{
    @include styles.lms-component-styles-outline();
    
    border-style: solid;
    border-color: transparent;
    border-width: var(--lms-navigation-drawer-focus-indicator-thickness);
}

.NavigationDrawerItem-content{
    @include styles.lms-component-styles-content();
    @include styles.lms-flex-container-row(left);
    @include styles.lms-flex-container-gap(0, 12);
    @include styles.lms-flex-item-auto();

    text-decoration: none;
    padding: 0 var(--lms-navigation-drawer-active-indicator-padding);
    outline: none;
}

.NavigationDrawerItem-icon{
    height: var(--lms-navigation-drawer-active-icon-size);
}

