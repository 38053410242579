@use '../../../styles';

.RevertIdentity{
    
    @include styles.lms-flex-container-column();
    @include styles.lms-flex-container-gap(16, 0);
    
    padding: 24px;
    
    & p {
        color: var(--md-sys-color-on-surface);
    }

}
