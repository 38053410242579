@use '../../../../styles';

.SettingsEmptyPane {
    @include styles.lms-flex-container-column('center');
    @include styles.lms-flex-container-gap(16, 0);
    @include styles.lms-flex-item-auto();

    & md-icon{
        color: var(--md-sys-color-on-surface-variant);
    }
    
    & > p {
        color: var(--md-sys-color-on-surface);
    }
}