@mixin _base ($direction){
    $name: #{unique-id()};

    @keyframes #{$name} {
        @content;
    }

    animation-name: $name;
    animation-iteration-count: 1;
    animation-direction: $direction;
}

@mixin standard ($direction: normal){
    @include _base($direction){
        @content;
    }
    animation-duration: var(--md-sys-motion-duration-medium2);
    animation-timing-function: var(--md-sys-motion-easing-standard);
}

@mixin emphasized-accelerate ($direction: normal){
    @include _base($direction){
        @content;
    }
    animation-duration: var(--md-sys-motion-duration-short4);
    animation-timing-function: var(--md-sys-motion-easing-emphasized-accelerate);
}

@mixin emphasized-decelerate ($direction: normal){
    @include _base($direction){
        @content;
    }
    animation-duration: var(-md-sys-motion-duration-medium4);
    animation-timing-function: var(--md-sys-motion-easing-emphasized-decelerate);
}

