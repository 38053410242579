@use '../../../../styles' as styles;

.SettingsListPane-group{

    @include styles.lms-flex-container-column();
    @include styles.lms-flex-container-gap(8, 0);

    & figcaption{
        @include styles.lms-typography-font('label-medium');

        color: var(--md-sys-color-on-surface-variant);
    }

    & md-list{
        
        background-color: var(--md-sys-color-surface-container-highest);
        border-radius: var(--md-sys-shape-corner-medium);
    }

}