
@mixin font($font-name, $is-prominent: false) {
    font-family: var(--md-sys-typescale-#{$font-name}-font);
    font-size: var(--md-sys-typescale-#{$font-name}-size);
    font-weight: var(--md-sys-typescale-#{$font-name}-weight);
    letter-spacing: var(--md-sys-typescale-#{$font-name}-tracking);
    line-height: var(--md-sys-typescale-#{$font-name}-line-height);

    @if $is-prominent {
        font-weight: var(--md-sys-typescale-#{$font-name}-weight-prominent);
    }

}