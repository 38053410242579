@use '../../../../../styles';

.AccountSelfCreationConfiguration{
     @include styles.lms-flex-item-auto();
     @include styles.lms-flex-container-row('left');
}
.AccountSelfCreationConfiguration-label{
     @include styles.lms-flex-container-column('left');
     @include styles.lms-flex-item-none();
     height: 48px;
     width: 160px;
     color: var(--md-sys-color-on-surface-variant);
}
.AccountSelfCreationConfiguration-buttons{
     @include styles.lms-flex-item-auto();     
}