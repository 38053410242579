@use '../../../styles' as styles;

.Form{
    @include styles.lms-flex-container-column();
    @include styles.lms-flex-container-column-fill();
    @include styles.lms-flex-container-gap(8, 0);

    &> *{
        @include styles.lms-flex-item-none();
    }
}

.Form-hiddenSubmitButton{

    display: none;

}