@use '../../../styles';

.Text {

    &.Text--body-large{
        @include styles.lms-typography-font('body-large');
    }
    
    &.Text--body-medium{
        @include styles.lms-typography-font('body-medium');
    }

    &.Text--body-small{
        @include styles.lms-typography-font('body-small');
    }

    
    &.Text--display-large{
        @include styles.lms-typography-font('display-large');
    }
    
    &.Text--display-medium{
        @include styles.lms-typography-font('display-medium');
    }

    &.Text--display-small{
        @include styles.lms-typography-font('display-small');
    }

    
    &.Text--headline-large{
        @include styles.lms-typography-font('headline-large');
    }
    
    &.Text--headline-medium{
        @include styles.lms-typography-font('headline-medium');
    }

    &.Text--headline-small{
        @include styles.lms-typography-font('headline-small');
    }

    
    &.Text--label-large{
        @include styles.lms-typography-font('label-large');
    }
    
    &.Text--label-medium{
        @include styles.lms-typography-font('label-medium');
    }

    &.Text--label-small{
        @include styles.lms-typography-font('label-small');
    }

    
    &.Text--title-large{
        @include styles.lms-typography-font('title-large');
    }
    
    &.Text--title-medium{
        @include styles.lms-typography-font('title-medium');
    }

    &.Text--title-small{
        @include styles.lms-typography-font('title-small');
    }
}