@use 'sass:map';
@use '@material/web/elevation/elevation';
@use '../../../../styles/tokens';
@use '../../../../styles';

$lms-top-app-bar: tokens.lms-comp-top-app-bar-values();

.TopAppBar{
    --lms-top-app-bar-container-color: #{map-get($lms-top-app-bar, 'container-color')};
    $lms-top-app-bar-container-elevation: #{map-get($lms-top-app-bar, 'container-elevation')};
    --lms-top-app-bar-container-height: #{map-get($lms-top-app-bar, 'container-height')};
    --lms-top-app-bar-headline-color: #{map-get($lms-top-app-bar, 'headline-color')};
    --lms-top-app-bar-left-right-padding: #{map-get($lms-top-app-bar, 'left-right-padding')};
    --lms-top-app-bar-on-scroll-container-color: #{map-get($lms-top-app-bar, 'on-scroll-container-color')};
    $lms-top-app-bar-on-scroll-container-elevation: #{map-get($lms-top-app-bar, 'on-scroll-container-elevation')};
    $lms-top-app-bar-on-scroll-container-shadow-color: #{map-get($lms-top-app-bar, 'on-scroll-container-shadow-color')};

    @include styles.lms-component-styles-container();

    height: var(--lms-top-app-bar-container-height);
    
    &> md-elevation {

        // transition-duration: 3000;//var(--md-motion-duration-medium1);
        // transition-property: box-shadow, background-color;
        // transition-timing-function: var(--md-motion-easing-standard);

        @include styles.lms-component-styles-elevation(
            $lms-top-app-bar-container-elevation
        );

    }

    &.is-scrolling{

        & .TopAppBar-background{            
            background-color: var(--lms-top-app-bar-on-scroll-container-color);
        }        

        &> md-elevation {
            @include styles.lms-component-styles-elevation(
                $lms-top-app-bar-on-scroll-container-elevation, 
                $lms-top-app-bar-on-scroll-container-shadow-color
            );
        }
    }
    
}

.TopAppBar-background{
    @include styles.lms-component-styles-background();
    
    background-color: var(--lms-top-app-bar-container-color);
}

.TopAppBar-outline{
    @include styles.lms-component-styles-outline();
}

.TopAppBar-content{
    @include styles.lms-component-styles-content();
    @include styles.lms-flex-container-row();
    @include styles.lms-flex-container-gap(0, #{map-get($lms-top-app-bar, 'padding-between-elements')});
    @include styles.lms-flex-item-auto();

    padding: 0 var(--lms-top-app-bar-left-right-padding);    

    &>*{
        @include styles.lms-flex-container-column('left');
    }
}

.TopAppBar--showLeadingIcon .TopAppBar-content{
    padding: 0 8px;   
}

.TopAppBar-headline{

    @include styles.lms-flex-item-auto();

    color: var(--lms-top-app-bar-headline-color);

} 

// .lms-top-app-bar {

//     $lms-comp-top-app-bar: styles.lms-comp-top-app-bar-values();

//     * {
//         pointer-events: auto;
//     }

//     &.lms-top-app-bar_container {
//         @include styles.lms-component-styles-container();

//         background-color: map-get($lms-comp-top-app-bar, 'container-color');
//         height: map-get($lms-comp-top-app-bar, 'container-height');
        
//     }

//     .lms-top-app-bar_elevation{
//         // @include styles.lms-elevation-styles-shared();
//         // @include styles.lms-elevation-styles-level(map-get($lms-comp-top-app-bar, 'container-elevation'));
//     }

//     .lms-top-app-bar_state{
//         @include styles.lms-component-styles-state();
//     }

//     .lms-top-app-bar_content {
//         @include styles.lms-component-styles-content();
//         @include styles.lms-flex-container-column();
        
//         &>div{
//             @include styles.lms-flex-item-auto();
//             @include styles.lms-flex-container-row();
//             @include styles.lms-flex-container-align-center();
//             @include styles.lms-flex-container-gap(0, map-get($lms-comp-top-app-bar, 'padding-between-elements'));
            
//             padding: 0 map-get($lms-comp-top-app-bar, 'left-right-padding');    
//         }

//         .lms-top-app-bar_headline {
//             @include styles.lms-flex-item-auto();

//             h1 {
//                 @include styles.lms-typography-font('title-large');
//             }
//         }
//     }

//     // Scrolling
//     &.lms-top-app-bar_container.lms-top-app-bar_container-scrolling {

//         background-color: map-get($lms-comp-top-app-bar, 'on-scroll-container-color');

//         .lms-top-app-bar_elevation{
//             // @include styles.lms-elevation-styles-level(map-get($lms-comp-top-app-bar, 'on-scroll-container-elevation'));
//         }
        
//     }


// }