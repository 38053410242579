@use 'sass:map';
@use '@material/web/elevation/elevation';
@use '../../../../styles/tokens';

$lms-elevated-card: tokens.lms-comp-elevated-card-values();

@mixin theme($tokens) {

    @each $token, $value in $tokens {
  
      @if $value {
        --md-elevated-card-#{$token}: #{$value};
      }
    }
}
  

.ElevatedCard{

    @include theme($lms-elevated-card);

    // --md-elevated-card-container-color: #{map.get($lms-elevated-card, 'container-color')};
    // --md-elevated-card-container-elevation: #{map.get($lms-elevated-card, 'container-elevation')};
    // --md-elevated-card-container-shadow-color: #{map.get($lms-elevated-card, 'container-shadow-color')};
    // --md-elevated-card-container-shape: #{map.get($lms-elevated-card, 'container-shape')};

    border-radius: var(--md-elevated-card-container-shape);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    // Reset z-index for background elements.
    z-index: 0;

    md-elevation,
    .background,
    .outline {
        border-radius: inherit;
        inset: 0;
        pointer-events: none;
        position: absolute;
    }

     // Separate element will be needed for disabled opacities (b/307361748)
    .background {
        background: var(--md-elevated-card-container-color);
        // Place behind content.
        z-index: -1;
    }

    // Separate element needed to overlay on top of content as opposed to
    // background, which is behind content. All cards have an outline for HCM.
    .outline {
        // A default transparent border that is visible in HCM
        border: 1px solid transparent;
        // Outline should be placed on top of content, such as images.
        z-index: 1;
    }

    md-elevation {
        // Place behind content.
        z-index: -1;
    
        @include elevation.theme(
            (
                'level': var(--md-elevated-card-container-elevation),
                'shadow-color': var(--md-elevated-card-container-shadow-color),
            )
        );
    }


    .content {
        // Allow content to inherit card's border radius
        border-radius: inherit;
    }
}