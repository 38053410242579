@use '../../../../../styles';

.Identity{
    
    @include styles.lms-flex-container-column();
    @include styles.lms-flex-container-gap(16, 0);
    
    padding: 16px;
    padding-top: 0;
    
    & h2,
    & p {
        color: var(--md-sys-color-on-surface);
    }

}
