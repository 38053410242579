@use 'sass:map';
@use 'sass:list';
@use '../../../../styles/tokens';
@use '../../../../styles';

$lms-list-item: tokens.lms-comp-list-item-values();

.ListItem{
    --md-list-item-bottom-space: #{map-get($lms-list-item, 'bottom-space')};
    --md-list-item-disabled-opacity: #{map-get($lms-list-item, 'disabled-opacity')};
    --md-list-item-hover-state-layer-color: #{map-get($lms-list-item, 'hover-state-layer-color')};
    --md-list-item-hover-state-layer-opacity: #{map-get($lms-list-item, 'hover-state-layer-opacity')};
    --md-list-item-label-text-color: #{map-get($lms-list-item, 'label-text-color')};
    --md-list-item-label-text-font: #{map-get($lms-list-item, 'label-text-font')};
    --md-list-item-label-text-line-height: #{map-get($lms-list-item, 'label-text-line-height')};
    --md-list-item-label-text-size: #{map-get($lms-list-item, 'label-text-size')};
    --md-list-item-label-text-weight: #{map-get($lms-list-item, 'label-text-weight')};
    --md-list-item-leading-icon-color: #{map-get($lms-list-item, 'leading-icon-color')};
    --md-list-item-leading-space: #{map-get($lms-list-item, 'leading-space')};
    --md-list-item-one-line-container-height: #{map-get($lms-list-item, 'one-line-container-height')};
    --md-list-item-pressed-state-layer-color: #{map-get($lms-list-item, 'pressed-state-layer-color')};
    --md-list-item-pressed-state-layer-opacity: #{map-get($lms-list-item, 'pressed-state-layer-opacity')};
    --md-list-item-selected-container-color: var(--md-sys-color-secondary-container);
    --md-list-item-supporting-text-color: #{map-get($lms-list-item, 'supporting-text-color')};
    --md-list-item-supporting-text-font: #{map-get($lms-list-item, 'supporting-text-font')};
    --md-list-item-supporting-text-line-height: #{map-get($lms-list-item, 'supporting-text-line-height')};
    --md-list-item-supporting-text-size: #{map-get($lms-list-item, 'supporting-text-size')};
    --md-list-item-supporting-text-weight: #{map-get($lms-list-item, 'supporting-text-weight')};
    --md-list-item-top-space: #{map-get($lms-list-item, 'top-space')};
    --md-list-item-trailing-icon-color: #{map-get($lms-list-item, 'trailing-icon-color')};
    --md-list-item-trailing-space: #{map-get($lms-list-item, 'trailing-space')};
    --md-list-item-trailing-supporting-text-color: #{map-get($lms-list-item, 'trailing-supporting-text-color')};
    --md-list-item-trailing-supporting-text-font: #{map-get($lms-list-item, 'trailing-supporting-text-font')};
    --md-list-item-trailing-supporting-text-line-height: #{map-get($lms-list-item, 'supporting-text-line-height')};
    --md-list-item-trailing-supporting-text-size: #{map-get($lms-list-item, 'supporting-text-size')};
    --md-list-item-trailing-supporting-text-weight: #{map-get($lms-list-item, 'supporting-text-weight')};
    --md-list-item-two-line-container-height: #{map-get($lms-list-item, 'two-line-container-height')};

    &.is-selected{
        --md-list-item-label-text-color: var(--md-sys-color-on-secondary-container);
        --md-list-item-hover-state-layer-color: var(--md-sys-color-secondary);
        --md-list-item-pressed-state-layer-color: var(--md-sys-color-secondary);
        --md-list-item-trailing-icon-color: var(--md-sys-color-on-secondary-container);

        background-color: var(--md-list-item-selected-container-color);
    }

}


.ListItem-slot {
    @include styles.lms-flex-container-column();     
}

.ListItem-leadingAvatar{
    @include styles.lms-flex-container-column('center');  
    background-color: var(--md-sys-color-primary-container);
    border-radius: var(--md-sys-shape-corner-full);
    color: var(--md-sys-color-on-primary-container);
    height: 40px;
    width: 40px;
}