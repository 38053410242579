@use '../../../styles' as styles;

.ResetPassword {
  --reset-password-card-padding: 24px;
  --reset-password-card-padding-between-title-and-body: 16px;

  @include styles.lms-flex-container-column;
  @include styles.lms-flex-container-column-fill();

  padding-bottom: var(--reset-password-card-padding);

  & > header {
    padding-bottom: var(--reset-password-card-padding-between-title-and-body);
    padding-left: var(--reset-password-card-padding);
    padding-right: var(--reset-password-card-padding);
    padding-top: var(--reset-password-card-padding);

    & > h1 {
      @include styles.lms-typescale-font('headline-small');

      color: var(--md-sys-color-on-surface);
      text-align: center;
    }
  }
}

.ResetPassword-errorMessage{
  padding-bottom: 16px;
}

.ResetPassword-content {
  @include styles.lms-flex-container-column;
  @include styles.lms-flex-container-gap(16, 0);

  padding-left: var(--reset-password-card-padding);
  padding-right: var(--reset-password-card-padding);


}

.ResetPassword-validating{
  @include styles.lms-flex-container-column();
  @include styles.lms-flex-container-gap(16, 16);
  p{
    color: var(--md-sys-color-on-surface);
  }
}

.ResetPassword-actions{
    @include styles.lms-flex-container-column('center');
}