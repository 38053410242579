@use '../../../styles';

.BannerMessage {
    --lms-banner-message-error-container-color: var(--md-sys-color-error-container);
    --lms-banner-message-error-label-text-color: var(--md-sys-color-error);
    --lms-banner-message-error-icon-color: var(--md-sys-color-error);
    
    @include styles.lms-flex-container-row('left');
    @include styles.lms-flex-container-gap(8, 8);


    padding: 24px 16px;
}

.BannerMessage-label{
    @include styles.lms-flex-item-auto();
}

.BannerMessage-icon{
    @include styles.lms-flex-item-none();
}

.BannerMessage--error{
    background-color: var(--lms-banner-message-error-container-color);

    .BannerMessage-label{
        color: var(--lms-banner-message-error-label-text-color);
    }

    .BannerMessage-icon{
        @include styles.lms-flex-container-row('center');
        color: var(--lms-banner-message-error-label-text-color);
    }
}

