@use '../../../styles' as styles;


.AuthenticationLayout{
    --lms-authentication-layout-card-width: 800px;

    @include styles.lms-flex-container-column('center');
    @include styles.lms-flex-item-auto();

    background-color: var(--md-sys-color-background);
    // height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 24px;
}


@include styles.lms-layout-compact(){
    
    .AuthenticationLayout{
        --lms-authentication-layout-card-width: 100%;    
        @include styles.lms-flex-container-column();   
    }

    .AuthenticationLayout-companyLogo{
        display: none;
    }
    
    .AuthenticationLayout--default .AuthenticationLayout-content{
        @include styles.lms-flex-container-column();  
    }

}


@include styles.lms-layout-medium(){
    
    .AuthenticationLayout.AuthenticationLayout--doubleColumn{
        --lms-authentication-layout-card-width: 500px;        
    }
    
    .AuthenticationLayout--default .AuthenticationLayout-content{
        width: 500px;
    }

    .AuthenticationLayout-companyLogo{
        display: none;
    }
}

@include styles.lms-layout-expanded(){
    
    .AuthenticationLayout.AuthenticationLayout--doubleColumn{
        --lms-authentication-layout-card-width: 700px;        
    }
    
    .AuthenticationLayout--default .AuthenticationLayout-content{
            width: 500px;
    }
    
}

@include styles.lms-layout-large(){
    
    .AuthenticationLayout--default .AuthenticationLayout-content{ 
        width: 600px; 
    }    
    
}

@include styles.lms-layout-extra-large(){
     
    .AuthenticationLayout--default .AuthenticationLayout-content{ 
        width: 600px; 
    }
    
}

.AuthenticationLayout-companyLogo{
    max-width: 300px;
    padding: 16px;
}

// .AuthenticationLayout-card{
//     // transition-property: height;
//     // transition-duration: var(--md-motion-duration-long2);
//     // transition-timing-function: var(--md-motion-easing-emphasized);

// }


.AuthenticationLayout--doubleColumn{
    
    .AuthenticationLayout-card{
        width: var(--lms-authentication-layout-card-width);
    }

}


.AuthenticationLayout-cardContent{
    @include styles.lms-flex-container-column;
    @include styles.lms-flex-item-auto;
}
// .AuthenticationLayout-cardContent{
//     // opacity: 0;

//     // transition-property: opacity;
//     // transition-duration: 5s;//var(--md-motion-duration-short);
//     // transition-timing-function: var(--md-motion-easing-emphasized);

//     &.is-initialized{
//         // opacity: 1;
//     }
// }

