@use 'sass:map';
// @use 'sass:list';
@use '../../../../styles/tokens';
@use '../../../../styles';

$lms-menu-item: tokens.lms-comp-menu-item-values();

.MenuItem{
    --md-menu-item-bottom-space: #{map-get($lms-menu-item, 'bottom-space')};
    --md-menu-item-container-color: #{map-get($lms-menu-item, 'container-color')};
    --md-menu-item-disabled-opacity: #{map-get($lms-menu-item, 'disabled-opacity')};
    --md-menu-item-hover-state-layer-color: #{map-get($lms-menu-item, 'hover-state-layer-color')};
    --md-menu-item-hover-state-layer-opacity: #{map-get($lms-menu-item, 'hover-state-layer-opacity')};
    --md-menu-item-label-text-color: #{map-get($lms-menu-item, 'label-text-color')};
    --md-menu-item-label-text-font: #{map-get($lms-menu-item, 'label-text-font')};
    --md-menu-item-label-text-line-height: #{map-get($lms-menu-item, 'label-text-line-height')};
    --md-menu-item-label-text-size: #{map-get($lms-menu-item, 'label-text-size')};
    --md-menu-item-label-text-weight: #{map-get($lms-menu-item, 'label-text-weight')};
    --md-menu-item-leading-icon-color: #{map-get($lms-menu-item, 'leading-icon-color')};
    --md-menu-item-leading-space: #{map-get($lms-menu-item, 'leading-space')};
    --md-menu-item-one-line-container-height: #{map-get($lms-menu-item, 'one-line-container-height')};
    --md-menu-item-pressed-state-layer-color: #{map-get($lms-menu-item, 'pressed-state-layer-color')};
    --md-menu-item-pressed-state-layer-opacity: #{map-get($lms-menu-item, 'pressed-state-layer-opacity')};
    --md-menu-item-selected-container-color: #{map-get($lms-menu-item, 'selected-container-color')};
    --md-menu-item-selected-label-text-color: #{map-get($lms-menu-item, 'selected-label-text-color')};
    --md-menu-item-supporting-text-color: #{map-get($lms-menu-item, 'supporting-text-color')};
    --md-menu-item-supporting-text-font: #{map-get($lms-menu-item, 'supporting-text-font')};
    --md-menu-item-supporting-text-line-height: #{map-get($lms-menu-item, 'supporting-text-line-height')};
    --md-menu-item-supporting-text-size: #{map-get($lms-menu-item, 'supporting-text-size')};
    --md-menu-item-supporting-text-weight: #{map-get($lms-menu-item, 'supporting-text-weight')};
    --md-menu-item-top-space: #{map-get($lms-menu-item, 'top-space')};
    --md-menu-item-trailing-icon-color: #{map-get($lms-menu-item, 'trailing-icon-color')};
    --md-menu-item-trailing-space: #{map-get($lms-menu-item, 'trailing-space')};
    --md-menu-item-trailing-supporting-text-color: #{map-get($lms-menu-item, 'trailing-supporting-text-color')};
    --md-menu-item-trailing-supporting-text-font: #{map-get($lms-menu-item, 'trailing-supporting-text-font')};
    --md-menu-item-trailing-supporting-text-line-height: #{map-get($lms-menu-item, 'trailing-supporting-text-line-height')};
    --md-menu-item-trailing-supporting-text-size: #{map-get($lms-menu-item, 'trailing-supporting-text-size')};
    --md-menu-item-trailing-supporting-text-weight: #{map-get($lms-menu-item, 'trailing-supporting-text-weight')};
    --md-menu-item-two-line-container-height: #{map-get($lms-menu-item, 'two-line-container-height')};
    
}

.MenuItem-content{
    @include styles.lms-flex-container-row('left');
    @include styles.lms-flex-container-gap(0, 12);

    md-icon{
        color: var(--md-sys-color-on-surface-variant);
    }
}

.MenuItem-headline{
    @include styles.lms-typography-font('label-large');
}