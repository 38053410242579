@use '../../../../styles';

.SegmentedButtonSet{
    --md-segmented-button-set-container-height: 40px;
    --md-segmented-button-set-target-height: 48px;

    @include styles.lms-flex-container-row('left');
    
    height: var(--md-segmented-button-set-target-height);
    overflow: hidden;
}

.SegmentedButtonSet-buttons{
    // @include styles.lms-flex-item-none();

    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    grid-auto-rows: auto;
    width: 100%;
    height: var(--md-segmented-button-set-container-height);

    & > *:first-child{
        border-radius: var(--md-sys-shape-corner-full) 0 0 var(--md-sys-shape-corner-full);
    
    }

    & > *:last-child{
        border-radius: 0 var(--md-sys-shape-corner-full) var(--md-sys-shape-corner-full) 0;
    }
}
