@use '../../../styles';

.PasswordRule {
  @include styles.lms-flex-container-row('left');
  @include styles.lms-flex-container-gap(8,8);
  @include styles.lms-flex-item-auto();
  .PasswordRule-icon md-icon {      
    color: var(--md-sys-color-on-surface-variant);   
  }
  &.is-passed
  {
    .PasswordRule-icon md-icon {      
      color: #4C8955;   
    }
  }

  & > p{
    color: var(--md-sys-color-on-surface); 
  }
}

.PasswordRule-icon {   
  @include styles.lms-flex-container-column('center');
  @include styles.lms-flex-item-none();
}




