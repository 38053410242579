@use 'sass:map';

@use '../../../../styles/tokens';

$lms-menu: tokens.lms-comp-menu-values();

.Menu{
    --md-menu-container-color: #{map-get($lms-menu, 'container-color')};
    --md-menu-container-elevation: #{map-get($lms-menu, 'container-elevation')};
    --md-menu-container-shadow-color: #{map-get($lms-menu, 'container-shadow-color')};
    --md-menu-container-shape: #{map-get($lms-menu, 'container-shape')};


}