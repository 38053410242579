@use '../../../../../styles';

.Password{
    
    @include styles.lms-flex-container-column();
    @include styles.lms-flex-container-gap(16, 0);
    
    padding: 16px;

    & h2,
    & p {
        color: var(--md-sys-color-on-surface);
    }

}

.Password-dialog {
    max-height: min(600px,100% - 48px);
}