@use 'sass:map';

@use '../../../../styles/tokens';

$lms-list: tokens.lms-comp-list-values();


.List
{

    --md-list-container-color:#{map-get($lms-list, 'container-color')};
}