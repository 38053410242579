@use '../../../styles' as styles;

@use '@material/web/elevation/elevation';

.UnexpectedError{

    @include styles.lms-flex-container-column('center');
    @include elevation.theme(
        (
            'level': 'level2',
            'shadow-color': var(--md-sys-color-shadow),
        )
    );

    background-color: var(--md-sys-color-background);
    height: 100%;
    width: 100%;

}

.UnexpectedError-main{

    background-color: var(--md-sys-color-surface-container-low);
    border-radius: 16px;
    max-width: 800px;
    overflow: hidden;
    width: 80%;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.3);

}

.UnexpectedError-header{
    @include styles.lms-flex-container-column();

    background-color: var(--md-sys-color-primary-container);
    color: var(--md-sys-color-on-primary-container);
    padding: 48px;
}

.UnexpectedError-content{
    @include styles.lms-flex-container-column();
    @include styles.lms-flex-container-gap(16, 16);

    padding: 16px 24px;

    h2 {
        color: var(--md-sys-color-on-surface);
    }

    p {
        color: var(--md-sys-color-on-surface-variant);
    }
}

.UnexpectedError-actions{
    @include styles.lms-flex-container-column('left');

    padding: 0 24px 24px;
}

@include styles.lms-layout-compact(){
    
    .UnexpectedError{

        @include styles.lms-flex-container-column('top-center');
    
    }

    .UnexpectedError-main{

        border-radius: 0;
        box-shadow: none;
        width: 100%;
    
    }

    .UnexpectedError-content{
        background-color: var(--md-sys-color-background);
    }

    .UnexpectedError-actions{
        @include styles.lms-flex-container-column('center');
        background-color: var(--md-sys-color-background);
    }
}