@use '../../../../styles';

.AccountSelfCreationDetailPane{
    @include styles.lms-flex-container-column();
    height: 100%;
}

.AccountSelfCreationDetailPane-authenticationMethods{
    padding: 0 16px;
}

.AccountSelfCreationDetailPane-blank{
    @include styles.lms-flex-item-auto();
}

.AccountSelfCreationDetailPane-actions{
    @include styles.lms-flex-container-row(top-right);
    padding: 0 16px 16px;

    * {
        @include styles.lms-flex-item-none();
    }
}

.AccountSelfCreationDetailPane-enabledSection, 
.AccountSelfCreationDetailPane-approvalRequiredSection{
    padding:0 16px 
}

.AccountSelfCreationDetailPane-formConfigurationSection
{
    padding:0 16px 
}

.AccountSelfCreationDetailPane-formConfigurationOptions{
    @include styles.lms-flex-item-none();
    @include styles.lms-flex-container-column();
    @include styles.lms-flex-container-gap(8, 0);
}

.AccountSelfCreationDetailPane-formConfigurationSection-headerText{
    @include styles.lms-typography-font('body-large');

    color: var(--md-sys-color-on-surface);
}

