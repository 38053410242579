@use '../../../styles' as styles;

.CreateAccount {
    --create-account-page-card-padding: 24px;
    --create-account-page-card-padding-between-title-and-body: 16px;

    @include styles.lms-flex-container-column;
    @include styles.lms-flex-container-column-fill();

    padding-bottom: var(--create-account-page-card-padding);
    padding-left: var(--create-account-page-card-padding);
    padding-right: 16px;

    &>header {
        padding-bottom: var(--create-account-page-card-padding-between-title-and-body);
        padding-left: var(--create-account-page-card-padding);
        padding-right: var(--create-account-page-card-padding);
        padding-top: var(--create-account-page-card-padding);

        &>h1 {
            color: var(--md-sys-color-on-surface);
            text-align: center;
        }
    }

    &>form {
        @include styles.lms-flex-container-column('stretch');

        fieldset {
            border: none; // Remove default fieldset border
            padding: 0;
            margin: 0;
            min-width: 0;

            &:disabled {
                opacity: 0.6; // Slightly faded look for disabled state
                pointer-events: none; // Disable interactions within the fieldset
            }
        }
    }
}

.CreateAccount-errorMessage {
    padding-bottom: 8px;
}

.CreateAccount-formFields {
    @include styles.lms-flex-container-row('start-top', true);
    @include styles.lms-flex-container-gap(0, 8);

    &>* {
        @include styles.lms-flex-item-none;
        min-width: 372px;
    }
}

.CreateAccount-contentButton {
    @include styles.lms-flex-container-column('center');
}

.CreateAccount-backToSignIn {
    @include styles.lms-flex-container-row('center');
    @include styles.lms-typescale-font('body-medium');
}

@include styles.lms-layout-compact() {
    .CreateAccount {
        padding-left: 16px;
        padding-right: 16px;
    }

    .CreateAccount-formFields {
        @include styles.lms-flex-container-column();
        @include styles.lms-flex-item-auto();

        &>* {
            @include styles.lms-flex-item-auto();
        }
    }
}

@include styles.lms-layout-medium() {
    .CreateAccount {
        padding-right: var(--create-account-page-card-padding);
    }

    .CreateAccount-formFields {
        @include styles.lms-flex-container-column();
        @include styles.lms-flex-item-auto();

        &>* {
            @include styles.lms-flex-item-auto();
        }
    }
}

@include styles.lms-layout-expanded() {
    .CreateAccount {
        padding-right: var(--create-account-page-card-padding);
    }

    .CreateAccount-formFields {
        @include styles.lms-flex-container-column();
        @include styles.lms-flex-item-auto();

        &>* {
            @include styles.lms-flex-item-auto();
        }
    }
}