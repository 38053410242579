@use '../../styles';

.Splash{
    @include styles.lms-flex-container-column('center');

    background-color: var(--md-sys-color-background);
    height: 100%;

    img {
        max-width: 600px;
        width: 100%;
    }
}