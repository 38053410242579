@use '../../../styles' as styles;

.ForgotCredentials{
    --forgot-credentials-card-padding: 24px;
    --forgot-credentials-card-padding-between-title-and-body: 16px;

    @include styles.lms-flex-container-column;
    @include styles.lms-flex-container-column-fill();

    padding-bottom: var(--forgot-credentials-card-padding);

    &> header{
        padding-bottom: var(--forgot-credentials-card-padding-between-title-and-body);
        padding-left: var(--forgot-credentials-card-padding);
        padding-right: var(--forgot-credentials-card-padding);
        padding-top: var(--forgot-credentials-card-padding);

        &> h1{

            color: var(--md-sys-color-on-surface);
            text-align: center;
        }
    }

}

.ForgotCredentials-content{
    @include styles.lms-flex-container-column;
    @include styles.lms-flex-container-gap(16,0);

    padding-left: var(--forgot-credentials-card-padding);
    padding-right: var(--forgot-credentials-card-padding);
}

.ForgotCredentials-backToSignIn{
    @include styles.lms-flex-container-row('center');
    @include styles.lms-typescale-font('body-medium');
}

.SignIn-errorMessage{
    padding-bottom: 16px;
}