@use '../../../../styles';

.TonalPalette {
    @include styles.lms-flex-container-column();

    h3 {
        padding: 24px;
    }
}

.TonalPalette-palette {
    @include styles.lms-flex-container-row();
}

.TonalPalette-paletteItem{
    @include styles.lms-flex-container-column('center');
    width: 50px;
    height: 100px;
}