@use '../../../../styles';

.AuthenticationDetailPane{
    @include styles.lms-flex-container-column();
    height: 100%;
}

.AuthenticationDetailPane-authenticationMethods{
    padding: 0 16px;
}

.AuthenticationDetailPane-blank{
    @include styles.lms-flex-item-auto();
}

.AuthenticationDetailPane-actions{
    @include styles.lms-flex-container-row(top-right);
    padding: 0 16px 16px;

    * {
        @include styles.lms-flex-item-none();
    }
}