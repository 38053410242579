@use '../../../../styles';

.Switch{
    @include styles.lms-flex-container-row('left');
    @include styles.lms-flex-container-gap(8, 8);

    cursor: pointer;
}

.Switch-label{
    @include styles.lms-flex-item-auto();
    color: var(--md-sys-color-on-surface);
}