@use '../../../../styles';
.Dialog{
    &.is-fullScreen{
        --md-dialog-container-color: var(--md-sys-color-surface);
        @include styles.lms-flex-item-auto();
        width: 100%;
        height: 100%;
        border-start-start-radius:unset;
        border-start-end-radius:unset;
        border-end-start-radius:unset;
        border-end-end-radius:unset;
        max-height: none;
        max-width: none;
        background-color: var(--md-sys-color-surface);
        
        .Dialog-headline{
            @include styles.lms-flex-container-row('left');
            background-color: var(--md-sys-color-surface);
            padding: 0 8px;
            height: 56px;

            &>span{
                @include styles.lms-flex-item-none();
            }
            &>span.Dialog-headlineTitle{
                @include styles.lms-flex-item-auto();
            }
        }
        .Dialog-content{
            @include styles.lms-flex-item-auto();
            margin: 0;
            padding: 0;    
            background-color: var(--md-sys-color-surface);
            height: calc(100vh - 56px);
        }

    }

    .Dialog-content{
        @include styles.lms-flex-container-column();
        @include styles.lms-flex-item-auto();
        padding: 0;
    }
}
