@use '../../../../styles' as styles;

.CheckYourEmail{
    @include styles.lms-flex-container-column();
    @include styles.lms-flex-container-gap(16,0);

    p{
        color: var(--md-sys-color-on-surface);

        text-align: center;
    }

}

.CheckYourEmail-actions{
    @include styles.lms-flex-container-column('center');
    @include styles.lms-flex-container-gap(8,0);
}


.CheckYourEmail-actionCooldown{
    @include styles.lms-flex-container-row();
    @include styles.lms-flex-container-gap(4, 4);
    
    color: var(--md-sys-color-on-surface-variant);
}