@use 'sass:map';
@use '../../../../styles/tokens';

$lms-filled-button: tokens.lms-comp-filled-button-values();

.FilledButton {
    --md-filled-button-container-color: #{map.get($lms-filled-button, 'container-color')};
    --md-filled-button-container-elevation: #{map.get($lms-filled-button, 'container-elevation')};
    --md-filled-button-container-height: 40px;
    --md-filled-button-container-shadow-color: #{map.get($lms-filled-button, 'container-shadow-color')};
    --md-filled-button-container-shape: #{map.get($lms-filled-button, 'container-shape')};
    --md-filled-button-disabled-container-color: #{map.get($lms-filled-button, 'disabled-container-color')};
    --md-filled-button-disabled-container-elevation: #{map.get($lms-filled-button, 'disabled-container-elevation')};
    --md-filled-button-disabled-container-opacity: 0.12;
    --md-filled-button-disabled-label-text-color: #{map.get($lms-filled-button, 'disabled-label-text-color')};
    --md-filled-button-disabled-label-text-opacity: 0.38;
    --md-filled-button-focus-container-elevation: #{map.get($lms-filled-button, 'focus-container-elevation')};
    --md-filled-button-focus-label-text-color: #{map.get($lms-filled-button, 'focus-label-text-color')};
    --md-filled-button-focus-state-layer-color: #{map.get($lms-filled-button, 'focus-state-layer-color')};
    --md-filled-button-focus-state-layer-opacity: #{map.get($lms-filled-button, 'focus-state-layer-opacity')};
    --md-filled-button-hover-container-elevation: #{map.get($lms-filled-button, 'hover-container-elevation')};
    --md-filled-button-hover-label-text-color: #{map.get($lms-filled-button, 'hover-label-text-color')};
    --md-filled-button-hover-state-layer-color: #{map.get($lms-filled-button, 'hover-state-layer-color')};
    --md-filled-button-hover-state-layer-opacity: #{map.get($lms-filled-button, 'hover-state-layer-opacity')};
    --md-filled-button-label-text-color: #{map.get($lms-filled-button, 'label-text-color')};
    --md-filled-button-label-text-font: #{map.get($lms-filled-button, 'label-text-font')};
    --md-filled-button-label-text-line-height: #{map.get($lms-filled-button, 'label-text-line-height')};
    --md-filled-button-label-text-size: #{map.get($lms-filled-button, 'label-text-size')};
    --md-filled-button-label-text-tracking: #{map.get($lms-filled-button, 'label-text-tracking')};
    --md-filled-button-label-text-weight: #{map.get($lms-filled-button, 'label-text-weight')};
    --md-filled-button-pressed-container-elevation: #{map.get($lms-filled-button, 'pressed-container-elevation')};
    --md-filled-button-pressed-label-text-color: #{map.get($lms-filled-button, 'pressed-label-text-color')};
    --md-filled-button-pressed-state-layer-color: #{map.get($lms-filled-button, 'pressed-state-layer-color')};
    --md-filled-button-pressed-state-layer-opacity: #{map.get($lms-filled-button, 'pressed-state-layer-opacity')};
    --md-filled-button-with-icon-disabled-icon-color: #{map.get($lms-filled-button, 'with-icon-disabled-icon-color')};
    --md-filled-button-with-icon-disabled-icon-opacity: 0.38;
    --md-filled-button-with-icon-focus-icon-color: #{map.get($lms-filled-button, 'with-icon-focus-icon-color')};
    --md-filled-button-with-icon-hover-icon-color: #{map.get($lms-filled-button, 'with-icon-hover-icon-color')};
    --md-filled-button-with-icon-icon-color: #{map.get($lms-filled-button, 'with-icon-icon-color')};
    --md-filled-button-with-icon-icon-size: 18px;
    --md-filled-button-with-icon-pressed-icon-color: #{map.get($lms-filled-button, 'with-icon-pressed-icon-color')};
}

// .lms-filled-button {

//     $lms-comp-button-filled: styles.lms-comp-button-filled-values();

//     // State - Enabled
//     &.lms-filled-button_container{
//         @include styles.lms-component-styles-container();

//         background-color: map-get($lms-comp-button-filled, 'container-color');
//         border-radius: map-get($lms-comp-button-filled, 'container-shape');
//         height: map-get($lms-comp-button-filled, 'container-height');
        

//     }

//     & .lms-filled-button_elevation{
//         // @include styles.lms-elevation-styles-shared();
//         // @include styles.lms-elevation-styles-level(map-get($lms-comp-button-filled, 'container-elevation'));
//     }

//     & .lms-filled-button_state{
//         @include styles.lms-component-styles-state();
//     }

//     & .lms-filled-button_content{
//         @include styles.lms-component-styles-content();
        
//         pointer-events: auto;

//         &> button{
//             @include styles.lms-flex-item-auto();       

//             padding-left: map-get($lms-comp-button-filled, 'content-left-right-padding'); 
//             padding-right: map-get($lms-comp-button-filled, 'content-left-right-padding'); 
       
//         }

//         & .lms-filled-button_content-label-text{
//             @include styles.lms-typography-font('label-large');

//             color: map-get($lms-comp-button-filled, 'content-label-color');
        
//         }
//     }
    
//     // State - Focus
//     &:focus-within{                     
        
//         & .lms-filled-button_elevation{
            
//             // @include styles.lms-elevation-styles-level(map-get($lms-comp-button-filled, 'focus-container-elevation'));
//         }

//         & .lms-filled-button_state{

//             background-color: map-get($lms-comp-button-filled, 'focus-state-layer-color');
//             opacity: map-get($lms-comp-button-filled, 'focus-state-layer-opacity');
            
//         }
//     }

//     // State - Hover
//     &:hover,
//     &:hover *{             
//         cursor: pointer;
//     }

//     &:hover{

//         & .lms-filled-button_elevation{
//             // @include styles.lms-elevation-styles-level(map-get($lms-comp-button-filled, 'hover-container-elevation'));
//         }
        
//         & .lms-filled-button_state{

//             background-color: map-get($lms-comp-button-filled, 'hover-state-layer-color');
//             opacity: map-get($lms-comp-button-filled, 'hover-state-layer-opacity');
            
//         }
    
//     }

//     // State - Pressed
//     &:active{

//         & .lms-filled-button_elevation{
//             // @include styles.lms-elevation-styles-level(map-get($lms-comp-button-filled, 'pressed-container-elevation'));
//         }
        
//         & .lms-filled-button_state{

//             background-color: map-get($lms-comp-button-filled, 'pressed-state-layer-color');
//             opacity: map-get($lms-comp-button-filled, 'pressed-state-layer-opacity');
            
//         }

//     }

// }