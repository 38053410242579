@use '../../../../styles' as styles;

.SendResetPasswordEmail{
    @include styles.lms-flex-container-column();
    @include styles.lms-flex-container-gap(16,0);

    p{
        color: var(--md-sys-color-on-surface);
        text-align: center;
    }

}

.SendResetPasswordEmail-actions{
    @include styles.lms-flex-container-column('center');
}
