@use '../../../styles';

.UserSelector{
    @include styles.lms-flex-container-column();

    padding-top: 16px;
}

.UserSelector-searchField{
    @include styles.lms-flex-item-none();
    padding: 0 16px 16px;
}

.UserSelector-scroller{
    @include styles.lms-flex-container-column();
    @include styles.lms-flex-item-auto();
    overflow-y: auto;
    max-height: 330px;
    height: fit-content;
}

.UserSelector-results{
    display: block;
}
.UserSelector-empty{
    color: var(--md-sys-color-on-surface-variant);
    padding: 0 24px;
}


@include styles.lms-layout-compact(){
    
    .UserSelector-scroller{
        max-height: calc(100vh - 150px);
    }

}