@use '../../../styles' as styles;

.SignIn{
    --sign-in-page-card-padding: 24px;
    --sign-in-page-card-padding-between-title-and-body: 16px;

    @include styles.lms-flex-container-column;
    @include styles.lms-flex-container-column-fill();

    padding-bottom: var(--sign-in-page-card-padding);

    &> header{
        padding-bottom: var(--sign-in-page-card-padding-between-title-and-body);
        padding-left: var(--sign-in-page-card-padding);
        padding-right: var(--sign-in-page-card-padding);
        padding-top: var(--sign-in-page-card-padding);

        &> h1{
            color: var(--md-sys-color-on-surface);
            text-align: center;
        }
    }

}

.SignIn-content-container{
    @include styles.lms-flex-container-column();
    padding-left: var(--sign-in-page-card-padding);
    padding-right: var(--sign-in-page-card-padding);
}

.SignIn-content{
    @include styles.lms-flex-container-column;
    @include styles.lms-flex-container-gap(16,0);
    max-width:260;

}
