@use '../../../../styles';

.SegmentedButton{
    --md-segmented-button-disabled-icon-color: var(--md-sys-color-on-surface);
    --md-segmented-button-disabled-icon-opacity: 0.38;
    --md-segmented-button-disabled-label-text-color: var(--md-sys-color-on-surface);
    --md-segmented-button-disabled-label-text-opacity: 0.38;
    --md-segmented-button-focus-state-layer-opacity: var(--md-sys-state-focus-state-layer-opacity);
    --md-segmented-button-hover-state-layer-opacity: var(--md-sys-state-hover-state-layer-opacity);
    --md-segmented-button-pressed-state-layer-opacity: var(--md-sys-state-pressed-state-layer-opacity);
    --md-segmented-button-disabled-outline-color: var(--md-sys-color-on-surface);
    --md-segmented-button-disabled-outline-opacity: 0.12;
    --md-segmented-button-outline-color: var(--md-sys-color-outline);
    --md-segmented-button-outline-width: 1px;
    --md-segmented-button-selected-container-color: var(--md-sys-color-secondary-container);
    --md-segmented-button-selected-focus-icon-color: var(--md-sys-color-on-secondary-container);
    --md-segmented-button-selected-focus-label-text-color: var(--md-sys-color-on-secondary-container);
    --md-segmented-button-selected-focus-state-layer-color: var(--md-sys-color-on-secondary-container);
    --md-segmented-button-selected-hover-label-text-color: var(--md-sys-color-on-secondary-container);
    --md-segmented-button-selected-hover-state-layer-color: var(--md-sys-color-on-secondary-container);
    --md-segmented-button-selected-icon-color: var(--md-sys-color-on-secondary-container);
    --md-segmented-button-selected-label-text-color: var(--md-sys-color-on-secondary-container);
    --md-segmented-button-selected-pressed-icon-color: var(--md-sys-color-on-secondary-container);
    --md-segmented-button-selected-pressed-label-text-color: var(--md-sys-color-on-secondary-container);
    --md-segmented-button-selected-pressed-state-layer-color: var(--md-sys-color-on-secondary-container);
    --md-segmented-button-unselected-focus-icon-color: var(--md-sys-color-on-surface);
    --md-segmented-button-unselected-focus-label-text-color: var(--md-sys-color-on-surface);
    --md-segmented-button-unselected-focus-state-layer-color: var(--md-sys-color-on-surface);
    --md-segmented-button-unselected-hover-label-text-color: var(--md-sys-color-on-surface);
    --md-segmented-button-unselected-hover-state-layer-color: var(--md-sys-color-on-surface);
    --md-segmented-button-unselected-icon-color: var(--md-sys-color-on-surface);
    --md-segmented-button-unselected-label-text-color: var(--md-sys-color-on-surface);
    --md-segmented-button-unselected-pressed-icon-color: var(--md-sys-color-on-surface);
    --md-segmented-button-unselected-pressed-label-text-color: var(--md-sys-color-on-surface);
    --md-segmented-button-unselected-pressed-state-layer-color: var(--md-sys-color-on-surface);
    
    @include styles.lms-component-styles-container();

    cursor: pointer;

    &:focus-within {

        .SegmentedButton-label{
            color: var(--md-segmented-button-unselected-focus-label-text-color);
        } 
        
        .SegmentedButton-stateLayer{
            background-color: var(--md-segmented-button-unselected-focus-state-layer-color);
            opacity: var(--md-segmented-button-focus-state-layer-opacity);
        }

        .SegmentedButton-icon{
            color: var(--md-segmented-button-unselected-focus-icon-color);
        }

    }

    &:hover{

        .SegmentedButton-label{
            color: var(--md-segmented-button-unselected-hover-label-text-color);
        } 
        
        .SegmentedButton-stateLayer{
            background-color: var(--md-segmented-button-unselected-hover-state-layer-color);
            opacity: var(--md-segmented-button-hover-state-layer-opacity);
        }
    }

    &:active{
        .SegmentedButton-label{
            color: var(--md-segmented-button-unselected-pressed-label-text-color);
        } 
        
        .SegmentedButton-stateLayer{
            background-color: var(--md-segmented-button-unselected-pressed-state-layer-color);
            opacity: var(--md-segmented-button-pressed-state-layer-opacity);
        }

        .SegmentedButton-icon{
            color: var(--md-segmented-button-unselected-pressed-icon-color);
        }
    }

    &.is-selected{

        .SegmentedButton-background{
            background-color: var(--md-segmented-button-selected-container-color);
        }
        
        .SegmentedButton-label{
            color: var(--md-segmented-button-selected-label-text-color);
        }
        
        .SegmentedButton-icon{
            color: var(--md-segmented-button-selected-icon-color);
        }

        &:focus-within {

            .SegmentedButton-label{
                color: var(--md-segmented-button-selected-focus-label-text-color);
            } 
            
            .SegmentedButton-stateLayer{
                background-color: var(--md-segmented-button-selected-focus-state-layer-color);
                opacity: var(--md-segmented-button-focus-state-layer-opacity);
            }

            .SegmentedButton-icon{
                color: var(--md-segmented-button-selected-focus-icon-color);
            }
    
        }

        &:hover{

            .SegmentedButton-label{
                color: var(--md-segmented-button-selected-hover-label-text-color);
            } 
    
            .SegmentedButton-stateLayer{
                background-color: var(--md-segmented-button-selected-hover-state-layer-color);
                opacity: var(--md-segmented-button-hover-state-layer-opacity);
            }
        }

        &:active{
            .SegmentedButton-label{
                color: var(--md-segmented-button-selected-pressed-label-text-color);
            } 
            
            .SegmentedButton-stateLayer{
                background-color: var(--md-segmented-button-selected-pressed-state-layer-color);
                opacity: var(--md-segmented-button-pressed-state-layer-opacity);
            }
    
            .SegmentedButton-icon{
                color: var(--md-segmented-button-selected-pressed-icon-color);
            }
        }
    }

    &.is-disabled{

        cursor: default;

        .SegmentedButton-outline{
            border-color: var(--md-segmented-button-disabled-outline-color);
            opacity: var(--md-segmented-button-disabled-outline-opacity);
        }

        .SegmentedButton-label{
            color: var(--md-segmented-button-disabled-label-text-color);
            opacity: var(--md-segmented-button-disabled-label-text-opacity);
        }
        
        .SegmentedButton-icon{
            color: var(--md-segmented-button-disabled-icon-color);
            opacity: var(--md-segmented-button-disabled-icon-opacity);
        }

        .SegmentedButton-stateLayer{
            opacity: 0;
        }
    }
}

.SegmentedButton-background,
.SegmentedButton-stateLayer{
    @include styles.lms-component-styles-background();
}

.SegmentedButton-outline{
    @include styles.lms-component-styles-outline();

    border-width: var(--md-segmented-button-outline-width);
    border-color: var(--md-segmented-button-outline-color);
}

.SegmentedButton-content{
    @include styles.lms-component-styles-content();
    @include styles.lms-flex-container-row('center');
}

.SegmentedButton-label{
    color: var(--md-segmented-button-unselected-label-text-color);   
}

.SegmentedButton-icon{
    color: var(--md-segmented-button-unselected-icon-color);
    @include styles.lms-flex-container-row('center');
}