@use '../../../../styles';

.NavigationRail{
    --lms-navigation-rail-container-color: var(--md-sys-color-surface);
    --lms-navigation-rail-container-width: 80px;
    --lms-navigation-rail-container-left-right-padding: 12px;


    @include styles.lms-component-styles-container();

    width: var(--lms-navigation-rail-container-width);
    height: 100%;
    &.with-scroll
    {
        width: var(--lms-navigation-rail-container-width+10);
    }
}

.NavigationRail-background{
    @include styles.lms-component-styles-background();
    
    background-color: var(--lms-navigation-rail-container-color);
}

.NavigationRail-content{
    @include styles.lms-component-styles-content();

    ul{
        @include styles.lms-flex-container-column();
        @include styles.lms-flex-container-gap(12, 0);
        @include styles.lms-flex-item-auto();

    }
    overflow-y: auto;
    overflow-x: hidden;
    padding: 16px var(--lms-navigation-rail-container-left-right-padding);

   
}