@use '../../../../../styles';

.AuthenticationDetailPaneSSOProviders{
    @include styles.lms-flex-container-column();
    @include styles.lms-flex-container-gap(4, 4);

    padding: 0 16px;
}

.AuthenticationDetailPaneSSOProviders-ssoProvider-label{
    color: var(--md-sys-color-on-surface-variant);
    padding: 0 8px;
}

.AuthenticationDetailPaneSSOProviders-ssoProviderButton{
    max-width: 400px;
}

.AuthenticationDetailPaneSSOProviders-ssoProvider-headerText{
    @include styles.lms-typography-font('label-medium');

    color: var(--md-sys-color-on-surface);
}
