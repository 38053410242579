@use '../../../styles' as styles;

.SignOut{
    --sign-out-page-card-padding: 24px;
    --sign-out-page-card-padding-between-title-and-body: 16px;

    @include styles.lms-flex-container-column;
    @include styles.lms-flex-container-column-fill();

    padding-bottom: var(--sign-out-page-card-padding);

    &> header{
        padding-bottom: var(--sign-out-page-card-padding-between-title-and-body);
        padding-left: var(--sign-out-page-card-padding);
        padding-right: var(--sign-out-page-card-padding);
        padding-top: var(--sign-out-page-card-padding);

        &> h1{
            color: var(--md-sys-color-on-surface);
            text-align: center;
        }
       
    }

}

.SignOut-Body{
    @include styles.lms-flex-container-column;
    @include styles.lms-flex-container-column-fill();
    color: var(--md-sys-color-on-surface);
    text-align: center;
}


.SignOut-errorMessage{
    padding-bottom: 8px;
}

.SignOut-content{
    @include styles.lms-flex-container-column;
    @include styles.lms-flex-container-column-fill();  

    padding-left: var(--sign-out-page-card-padding);
    padding-right: var(--sign-out-page-card-padding);
}

.SignOut-actions{
    @include styles.lms-flex-container-column('center');    
    padding-top:16px;
    padding-bottom:16px;
}
