@use '../../../styles';

.MyProfile{

    @include styles.lms-flex-container-column();
    @include styles.lms-flex-container-gap(24, 24);

    padding: 8px 0;
}

@mixin section-in-rows (){

    .MyProfile {      
        @include styles.lms-flex-container-row('top-left');

        & > * {
            @include styles.lms-flex-item-auto();
        }

    }

}

@include styles.lms-layout-expanded(){
    @include section-in-rows();
}

@include styles.lms-layout-large(){
    @include section-in-rows();
}

@include styles.lms-layout-extra-large(){
    @include section-in-rows();
}