@use 'sass:map';
@use '../../../../styles/tokens';
@use '../../../../styles';

$lms-radio: tokens.lms-comp-radio-values();

.Radio{
    --md-radio-icon-color: #{map-get($lms-radio, 'radio-icon-color')};
    --md-radio-selected-icon-color: #{map-get($lms-radio , "radio-selected-icon-color")};
    --md-radio-icon-size: #{map-get($lms-radio , "radio-icon-size")};
    --md-radio-target-size: 40px;

    @include styles.lms-flex-container-row('left');

    > * {
        cursor: pointer;
    }

    label{
        color: var(--md-sys-color-on-surface);
    }

}

.Radio-target{
    @include styles.lms-flex-container-row('center');

    width: var(--md-radio-target-size);
    height: var(--md-radio-target-size);
}