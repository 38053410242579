@use '../../../../styles';

.NavigationBarItem {
    --lms-navigation-bar-active-focus-icon-color: var(--md-sys-color-on-secondary-container);
    --lms-navigation-bar-active-focus-label-text-color: var(--md-sys-color-on-surface);
    --lms-navigation-bar-active-focus-state-layer-color: var(--md-sys-color-on-surface);
    --lms-navigation-bar-active-hover-icon-color: var(--md-sys-color-on-secondary-container);
    --lms-navigation-bar-active-hover-label-text-color: var(--md-sys-color-on-surface);
    --lms-navigation-bar-active-hover-state-layer-color: var(--md-sys-color-on-surface);
    --lms-navigation-bar-active-pressed-icon-color: var(--md-sys-color-on-secondary-container);
    --lms-navigation-bar-active-pressed-label-text-color: var(--md-sys-color-on-surface);
    --lms-navigation-bar-active-pressed-state-layer-color: var(--md-sys-color-on-surface);
    --lms-navigation-bar-active-icon-color: var(--md-sys-color-on-secondary-container);
    --lms-navigation-bar-active-indicator-color: var(--md-sys-color-secondary-container);
    --lms-navigation-bar-active-indicator-height: 32px;
    --lms-navigation-bar-active-indicator-width: 64px;
    --lms-navigation-bar-focus-state-layer-opacity: var(--md-sys-state-focus-state-layer-opacity);
    --lms-navigation-bar-hover-state-layer-opacity: var(--md-sys-state-hover-state-layer-opacity);
    --lms-navigation-bar-pressed-state-layer-opacity: var(--md-sys-state-pressed-state-layer-opacity);
    --lms-navigation-bar-active-indicator-shape: var(--md-sys-shape-corner-full);
    --lms-navigation-bar-active-label-text-color: var(--md-sys-color-on-surface);
    --lms-navigation-bar-inactive-focus-icon-color: var(--md-sys-color-on-surface);
    --lms-navigation-bar-inactive-focus-label-text-color: var(--md-sys-color-on-surface);
    --lms-navigation-bar-inactive-focus-state-layer-color: var(--md-sys-color-on-surface);
    --lms-navigation-bar-inactive-hover-icon-color: var(--md-sys-color-on-surface);
    --lms-navigation-bar-inactive-hover-label-text-color: var(--md-sys-color-on-surface);
    --lms-navigation-bar-inactive-hover-state-layer-color: var(--md-sys-color-on-surface);
    --lms-navigation-bar-inactive-pressed-icon-color: var(--md-sys-color-on-surface);
    --lms-navigation-bar-inactive-pressed-label-text-color: var(--md-sys-color-on-surface);
    --lms-navigation-bar-inactive-pressed-state-layer-color: var(--md-sys-color-on-surface);
    --lms-navigation-bar-inactive-icon-color: var(--md-sys-color-on-surface-variant);
    --lms-navigation-bar-inactive-label-text-color: var(--md-sys-color-on-surface-variant);

    @include styles.lms-component-styles-container();

    width: var(--lms-navigation-bar-active-indicator-width);

    .NavigationBarItem-icon{
        @include styles.lms-flex-container-column(center);

        height: var(--lms-navigation-bar-active-indicator-height);
    }

    md-icon{
        color: var(--lms-navigation-bar-inactive-icon-color);
    }

    .NavigationBarItem-labelText{
        @include styles.lms-typescale-font('label-medium');

        color: var(--lms-navigation-bar-inactive-label-text-color);
        text-align: center;
    }

    &:focus-within{

        md-icon{
            color: var(--lms-navigation-bar-inactive-focus-icon-color);
        }

        .NavigationBarItem-labelText{
            color: var(--lms-navigation-bar-inactive-focus-label-text-color);
        }

        .NavigationBarItem-stateLayer{
            background-color: var(--lms-navigation-bar-inactive-focus-state-layer-color);
            opacity: var(--lms-navigation-bar-focus-state-layer-opacity);
        }
    }

    &:hover{

        md-icon{
            color: var(--lms-navigation-bar-inactive-hover-icon-color);
        }

        .NavigationBarItem-labelText{
            color: var(--lms-navigation-bar-inactive-hover-label-text-color);
        }

        .NavigationBarItem-stateLayer{
            background-color: var(--lms-navigation-bar-inactive-hover-state-layer-color);
            opacity: var(--lms-navigation-bar-hover-state-layer-opacity);
        }
    }
    
    &:active{

        md-icon{
            color: var(--lms-navigation-bar-inactive-pressed-icon-color);
        }

        .NavigationBarItem-labelText{
            color: var(--lms-navigation-bar-inactive-pressed-label-text-color);
        }

        .NavigationBarItem-stateLayer{
            background-color: var(--lms-navigation-bar-inactive-pressed-state-layer-color);
            opacity: var(--lms-navigation-bar-pressed-state-layer-opacity);
        }
    }

    &.is-active{

        md-icon{
            color: var(--lms-navigation-bar-active-icon-color);
        }

        .NavigationBarItem-labelText{
            color: var(--lms-navigation-bar-active-label-text-color);
        }

        .NavigationBarItem-background{
            background-color: var(--lms-navigation-bar-active-indicator-color);
        }

        &:focus-within{

            md-icon{
                color: var(--lms-navigation-bar-active-focus-icon-color);
            }

            .NavigationBarItem-labelText{
                color: var(--lms-navigation-bar-active-focus-label-text-color);
            }

            
            .NavigationBarItem-stateLayer{
                background-color: var(--lms-navigation-bar-active-focus-state-layer-color);
            }
        }

        &:hover{

            md-icon{
                color: var(--lms-navigation-bar-active-hover-icon-color);
            }

            .NavigationBarItem-labelText{
                color: var(--lms-navigation-bar-active-hover-label-text-color);
            }

            
            .NavigationBarItem-stateLayer{
                background-color: var(--lms-navigation-bar-active-hover-state-layer-color);
            }
        }

        &:active{

            md-icon{
                color: var(--lms-navigation-bar-active-pressed-icon-color);
            }

            .NavigationBarItem-labelText{
                color: var(--lms-navigation-bar-active-pressed-label-text-color);
            }

            
            .NavigationBarItem-stateLayer{
                background-color: var(--lms-navigation-bar-active-pressed-state-layer-color);
            }
        }
    }
}

.NavigationBarItem-background,
.NavigationBarItem-stateLayer{
    @include styles.lms-component-styles-background();

    border-radius: var(--lms-navigation-bar-active-indicator-shape);
    height: var(--lms-navigation-bar-active-indicator-height);
    width: var(--lms-navigation-bar-active-indicator-width);
}

.NavigationBarItem-content{
    @include styles.lms-component-styles-content();
    @include styles.lms-flex-container-column(top-center);
    @include styles.lms-flex-container-gap(4, 0);
    @include styles.lms-flex-item-auto();

    text-decoration: none;
    outline: none;
}