@use '../../../../styles' as styles;
@use 'sass:map';

$lms-comp-divider: styles.lms-comp-divider-values();

.Divider{
    --md-divider-bottom-margin: #{map-get($lms-comp-divider, 'divider-bottom-margin' )};
    --md-divider-color: #{map-get($lms-comp-divider, 'divider-color' )};
    --md-divider-full-width: #{map-get($lms-comp-divider, 'divider-full-width' )};
    --md-divider-thickness: #{map-get($lms-comp-divider, 'divider-thickness' )};

    margin-bottom: var(--md-divider-bottom-margin);
    width: var(--md-divider-full-width);
    padding-top:8px;
    padding-bottom:8px;
}

.Divider-content{
    background-color: var(--md-divider-color);
    height: var(--md-divider-thickness);
}
