@use '../../../styles' as styles;

.ChangePassword{
    --change-password-card-padding: 24px;
    --change-password-card-padding-between-title-and-body: 16px;

    @include styles.lms-flex-container-column();

    min-width: 320px;
    padding: 16px 24px 0;

    &> header{
        padding-bottom: var(--change-password-card-padding-between-title-and-body);
        padding-left: var(--change-password-card-padding);
        padding-right: var(--change-password-card-padding);
        padding-top: var(--change-password-card-padding);

        &> h2{
            @include styles.lms-typescale-font('title-large');

            color: var(--md-sys-color-on-surface);

        }
    }

}

.ChangePassword-content{
    @include styles.lms-flex-container-column();
    @include styles.lms-flex-container-gap(16,0);

}

.ChangePassword-actions{
    @include styles.lms-flex-container-column('center');
}