@use '../../../../styles';

.NavigationRailItem {
    --lms-navigation-rail-active-focus-icon-color: var(--md-sys-color-on-secondary-container);
    --lms-navigation-rail-active-focus-label-text-color: var(--md-sys-color-on-surface);
    --lms-navigation-rail-active-focus-state-layer-color: var(--md-sys-color-on-surface);
    --lms-navigation-rail-active-hover-icon-color: var(--md-sys-color-on-secondary-container);
    --lms-navigation-rail-active-hover-label-text-color: var(--md-sys-color-on-surface);
    --lms-navigation-rail-active-hover-state-layer-color: var(--md-sys-color-on-surface);
    --lms-navigation-rail-active-pressed-icon-color: var(--md-sys-color-on-secondary-container);
    --lms-navigation-rail-active-pressed-label-text-color: var(--md-sys-color-on-surface);
    --lms-navigation-rail-active-pressed-state-layer-color: var(--md-sys-color-on-surface);
    --lms-navigation-rail-active-icon-color: var(--md-sys-color-on-secondary-container);
    --lms-navigation-rail-active-indicator-color: var(--md-sys-color-secondary-container);
    --lms-navigation-rail-active-indicator-height: 32px;
    --lms-navigation-rail-active-indicator-width: 56px;
    --lms-navigation-rail-focus-state-layer-opacity: var(--md-sys-state-focus-state-layer-opacity);
    --lms-navigation-rail-hover-state-layer-opacity: var(--md-sys-state-hover-state-layer-opacity);
    --lms-navigation-rail-pressed-state-layer-opacity: var(--md-sys-state-pressed-state-layer-opacity);
    --lms-navigation-rail-destination-item-height: 56px;
    --lms-navigation-rail-active-indicator-shape: var(--md-sys-shape-corner-full);
    --lms-navigation-rail-active-label-text-color: var(--md-sys-color-on-surface);
    --lms-navigation-rail-inactive-focus-icon-color: var(--md-sys-color-on-surface);
    --lms-navigation-rail-inactive-focus-label-text-color: var(--md-sys-color-on-surface);
    --lms-navigation-rail-inactive-focus-state-layer-color: var(--md-sys-color-on-surface);
    --lms-navigation-rail-inactive-hover-icon-color: var(--md-sys-color-on-surface);
    --lms-navigation-rail-inactive-hover-label-text-color: var(--md-sys-color-on-surface);
    --lms-navigation-rail-inactive-hover-state-layer-color: var(--md-sys-color-on-surface);
    --lms-navigation-rail-inactive-pressed-icon-color: var(--md-sys-color-on-surface);
    --lms-navigation-rail-inactive-pressed-label-text-color: var(--md-sys-color-on-surface);
    --lms-navigation-rail-inactive-pressed-state-layer-color: var(--md-sys-color-on-surface);
    --lms-navigation-rail-inactive-icon-color: var(--md-sys-color-on-surface-variant);
    --lms-navigation-rail-inactive-label-text-color: var(--md-sys-color-on-surface-variant);

    @include styles.lms-component-styles-container();

    min-height: var(--lms-navigation-rail-destination-item-height);
    
    .NavigationRailItem-icon{
        @include styles.lms-flex-container-column(center);

        height: var(--lms-navigation-rail-active-indicator-height);
    }

    md-icon{
        color: var(--lms-navigation-rail-inactive-icon-color);
    }

    .NavigationRailItem-labelText{
        @include styles.lms-typescale-font('label-medium');

        color: var(--lms-navigation-rail-inactive-label-text-color);
        text-align: center;
    }

    &:focus-within{

        md-icon{
            color: var(--lms-navigation-rail-inactive-focus-icon-color);
        }

        .NavigationRailItem-labelText{
            color: var(--lms-navigation-rail-inactive-focus-label-text-color);
        }

        .NavigationRailItem-stateLayer{
            background-color: var(--lms-navigation-rail-inactive-focus-state-layer-color);
            opacity: var(--lms-navigation-rail-focus-state-layer-opacity);
        }
    }

    &:hover{

        md-icon{
            color: var(--lms-navigation-rail-inactive-hover-icon-color);
        }

        .NavigationRailItem-labelText{
            color: var(--lms-navigation-rail-inactive-hover-label-text-color);
        }

        .NavigationRailItem-stateLayer{
            background-color: var(--lms-navigation-rail-inactive-hover-state-layer-color);
            opacity: var(--lms-navigation-rail-hover-state-layer-opacity);
        }
    }
    
    &:active{

        md-icon{
            color: var(--lms-navigation-rail-inactive-pressed-icon-color);
        }

        .NavigationRailItem-labelText{
            color: var(--lms-navigation-rail-inactive-pressed-label-text-color);
        }

        .NavigationRailItem-stateLayer{
            background-color: var(--lms-navigation-rail-inactive-pressed-state-layer-color);
            opacity: var(--lms-navigation-rail-pressed-state-layer-opacity);
        }
    }

    &.is-active{

        md-icon{
            color: var(--lms-navigation-rail-active-icon-color);
        }

        .NavigationRailItem-labelText{
            color: var(--lms-navigation-rail-active-label-text-color);
        }

        .NavigationRailItem-background{
            background-color: var(--lms-navigation-rail-active-indicator-color);
        }

        &:focus-within{

            md-icon{
                color: var(--lms-navigation-rail-active-focus-icon-color);
            }

            .NavigationRailItem-labelText{
                color: var(--lms-navigation-rail-active-focus-label-text-color);
            }

            
            .NavigationRailItem-stateLayer{
                background-color: var(--lms-navigation-rail-active-focus-state-layer-color);
            }
        }

        &:hover{

            md-icon{
                color: var(--lms-navigation-rail-active-hover-icon-color);
            }

            .NavigationRailItem-labelText{
                color: var(--lms-navigation-rail-active-hover-label-text-color);
            }

            
            .NavigationRailItem-stateLayer{
                background-color: var(--lms-navigation-rail-active-hover-state-layer-color);
            }
        }

        &:active{

            md-icon{
                color: var(--lms-navigation-rail-active-pressed-icon-color);
            }

            .NavigationRailItem-labelText{
                color: var(--lms-navigation-rail-active-pressed-label-text-color);
            }

            
            .NavigationRailItem-stateLayer{
                background-color: var(--lms-navigation-rail-active-pressed-state-layer-color);
            }
        }
    }
}

.NavigationRailItem-background,
.NavigationRailItem-stateLayer{
    @include styles.lms-component-styles-background();

    border-radius: var(--lms-navigation-rail-active-indicator-shape);
    height: var(--lms-navigation-rail-active-indicator-height);
    width: var(--lms-navigation-rail-active-indicator-width);
}

.NavigationRailItem-content{
    @include styles.lms-component-styles-content();
    @include styles.lms-flex-container-column(top-center);
    @include styles.lms-flex-container-gap(4, 0);
    @include styles.lms-flex-item-auto();

    text-decoration: none;
    // padding: 0 var(--lms-navigation-drawer-active-indicator-padding);
    outline: none;
}