@use 'sass:map';
@use '../../../../styles/tokens';

$lms-icon-button: tokens.lms-comp-icon-button-values();

.IconButton{
    --md-icon-button-disabled-icon-color: #{map.get($lms-icon-button, 'disabled-icon-color')};
    --md-icon-button-disabled-icon-opacity: #{map.get($lms-icon-button, 'disabled-icon-opacity')};
    --md-icon-button-focus-icon-color: #{map.get($lms-icon-button, 'focus-icon-color')};
    --md-icon-button-hover-icon-color: #{map.get($lms-icon-button, 'hover-icon-color')};
    --md-icon-button-hover-state-layer-color: #{map.get($lms-icon-button, 'hover-state-layer-color')};
    --md-icon-button-hover-state-layer-opacity: #{map.get($lms-icon-button, 'hover-state-layer-opacity')};
    --md-icon-button-icon-color: #{map.get($lms-icon-button, 'icon-color')};
    --md-icon-button-icon-size: #{map.get($lms-icon-button, 'icon-size')};
    --md-icon-button-pressed-icon-color: #{map.get($lms-icon-button, 'pressed-icon-color')};
    --md-icon-button-pressed-state-layer-color: #{map.get($lms-icon-button, 'pressed-state-layer-color')};
    --md-icon-button-pressed-state-layer-opacity: #{map.get($lms-icon-button, 'pressed-state-layer-opacity')};
    --md-icon-button-selected-focus-icon-color: #{map.get($lms-icon-button, 'selected-focus-icon-color')};
    --md-icon-button-selected-hover-icon-color: #{map.get($lms-icon-button, 'selected-hover-icon-color')};
    --md-icon-button-selected-hover-state-layer-color: #{map.get($lms-icon-button, 'selected-hover-state-layer-color')};
    --md-icon-button-selected-hover-state-layer-opacity: #{map.get($lms-icon-button, 'selected-hover-state-layer-opacity')};
    --md-icon-button-selected-icon-color: #{map.get($lms-icon-button, 'selected-icon-color')};
    --md-icon-button-selected-pressed-icon-color: #{map.get($lms-icon-button, 'selected-pressed-icon-color')};
    --md-icon-button-selected-pressed-state-layer-color: #{map.get($lms-icon-button, 'selected-pressed-state-layer-color')};
    --md-icon-button-selected-pressed-state-layer-opacity: #{map.get($lms-icon-button, 'selected-pressed-state-layer-opacity')};
    --md-icon-button-state-layer-height: #{map.get($lms-icon-button, 'state-layer-height')};
    --md-icon-button-state-layer-shape: #{map.get($lms-icon-button, 'state-layer-shape')};
    --md-icon-button-state-layer-width: #{map.get($lms-icon-button, 'state-layer-width')};
}