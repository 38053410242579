@use 'sass:map';

@use './styles' as styles;
@use './styles/reset/reset.scss' as reset;

@include styles.lms-typeface-font-face();

:root {

    @include styles.lms-typescale-theme();
    @include styles.lms-color-theme();
    @include styles.lms-elevation-theme();
    @include styles.lms-shape-theme();
    @include styles.lms-state-theme();
    @include styles.lms-motion-theme();
}

@include reset.styles;


::backdrop{
  z-index: 100;
}
.scrim{
  z-index: 100;
  background-color: rebeccapurple !important;
}

// START: fade transition
.fade-enter {
    opacity: 0;
  }
  
  .fade-exit {
    opacity: 1;
  }
  
  .fade-enter-active {
    opacity: 1;
    transition: opacity 250ms ease;
  }
  
  .fade-exit-active {
    opacity: 0;
    transition: opacity 250ms ease;
  }
  // END: fade transition

  // START: page-slide transitions
.page-slide-right-enter {
    opacity: 0;
    transform: translate3d(150px, 0, 0);
  }
  
  .page-slide-left-enter {
    opacity: 0;
    transform: translate3d(-150px, 0, 0);
  }
  
  .page-slide-right-enter-active,
  .page-slide-left-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 350ms cubic-bezier(0.4, 0, 0, 1.5),
      transform 350ms cubic-bezier(0.4, 0, 0, 1.5);
  }
  
  .page-slide-right-exit,
  .page-slide-left-exit {
    opacity: 1;
  }
  
  .page-slide-right-exit-active,
  .page-slide-left-exit-active {
    opacity: 0;
    transition: opacity 250ms ease;
  }
  // END: page-slide transitions

  // .container {
  //   position: relative;
  // }
  
  // .page {
  //   position: absolute;
  //   left: 15px;
  //   right: 15px;
  // }
  // .page {
  //   opacity: 0;
  // }
  // .page-enter {
  //   opacity: 0;
  //   transform: scale(1.1);
  // }
  
  // .page-enter-active {
  //   opacity: 1;
  //   transform: scale(1);
  //   transition: opacity 20000ms, transform 20000ms;
  // }
  
  // .page-exit {
  //   opacity: 1;
  // }
  
  // .page-exit-active {
  //   opacity: 0;
  //   transition: opacity var(--md-sys-motion-duration-short4) var(--md-sys-motion-easing-emphasized-accelerate);
  // }
  