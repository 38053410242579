@use '../../../../styles';

.NavigationBar{
    --lms-navigation-bar-container-color: var(--md-sys-color-surface-container);
    --lms-navigation-bar-container-height: 80px;
    --lms-navigation-bar-container-top-padding: 12px;
    --lms-navigation-bar-container-bottom-padding: 16px;
    
    $lms-navigation-bar-container-elevation: 'level2';
    $lms-navigation-bar-container-shadow-color: var(--md-sys-color-shadow);

    @include styles.lms-component-styles-container();

    height: var(--lms-navigation-bar-container-height);
    width: 100%;

    md-elevation{

        @include styles.lms-component-styles-elevation(
            $lms-navigation-bar-container-elevation,
            $lms-navigation-bar-container-shadow-color
        );

    }
}

.NavigationBar-background{
    @include styles.lms-component-styles-background();
    
    background-color: var(--lms-navigation-bar-container-color);
}

.NavigationBar-content{
    @include styles.lms-component-styles-content();

    ul{
        @include styles.lms-flex-container-row();
        justify-content: space-around;
        @include styles.lms-flex-container-gap(0, 8);
        @include styles.lms-flex-item-auto();

        margin: 0
    }

    padding: var(--lms-navigation-bar-container-top-padding) 0 var(--lms-navigation-bar-container-bottom-padding);
}