
@mixin container-align-top-center {
    justify-content: flex-start;
    align-items: center;
}

@mixin container-align-top-left {
    justify-content: flex-start;
    align-items: flex-start;
}

@mixin container-align-top-right {
    justify-content: flex-start;
    align-items: flex-end;
}

@mixin container-align-center {
    justify-content: center;
    align-items: center;
}

@mixin container-align-left {
    justify-content: center;
    align-items: flex-start;
}

@mixin container-align-right {
    justify-content: center;
    align-items: flex-end;
}

@mixin container-align-bottom-center {
    justify-content: flex-end;
    align-items: center;
}

@mixin container-align-bottom-left {
    justify-content: flex-end;
    align-items: flex-start;
}

@mixin container-align-bottom-right {
    justify-content: flex-end;
    align-items: flex-end;
}

@mixin container-align-stretch {
    justify-content: stretch;
    align-items: stretch;
}

@mixin container-align-top-center {
    justify-content: flex-start;
    align-items: center;
}

@mixin container-column{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
}

@mixin container-column-fill {
    justify-content: stretch;
}

@mixin container-gap($row-gap, $column-gap){
    row-gap: #{$row-gap}px;
    column-gap: #{$column-gap}px;
}

@mixin container-row{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: stretch;
    align-items: flex-start;
}

@mixin container-row-fill {
    align-items: stretch;
}

@mixin container-wrap {
    flex-wrap: wrap;;
}

@mixin item-auto{
    flex: 1 1 auto;
}

@mixin item-none{
    flex: 0 0 auto;
}

$align-top-fill: top-fill;
$align-top-left: top-left;
$align-top-center: top-center;
$align-top-right: top-right;
$align-left: left;
$align-center: center;
$align-right: right;
$align-bottom-left: bottom-left;
$align-bottom-center: bottom-center;
$align-bottom-right: bottom-right;

@mixin container-column($align: $align-top-fill, $wrap: false) {

    display: flex;
    flex-direction: column;

    @if $wrap == true {
        flex-wrap: wrap;
    } @else {
        flex-wrap: nowrap;
    }
    
    @if $align == $align-top-fill {
    
        justify-content: flex-start;
        align-items: stretch;
    
    } @else if $align == $align-top-left{
        
        justify-content: flex-start;
        align-items: flex-start;
    
    } @else if $align == $align-top-center{
        
        justify-content: flex-start;
        align-items: center;
    
    } @else if $align == $align-top-center{
        
        justify-content: flex-start;
        align-items: flex-end;
    
    } @else if $align == $align-left{
        
        justify-content: center;
        align-items: flex-start;
    
    } @else if $align == $align-center{
        
        justify-content: center;
        align-items: center;
    
    } @else if $align == $align-center{
        
        justify-content: center;
        align-items: flex-end;
    
    } @else if $align == $align-bottom-left{
        
        justify-content: flex-end;
        align-items: flex-start;
    
    } @else if $align == $align-bottom-center{
        
        justify-content: flex-end;
        align-items: center;
    
    } @else if $align == $align-bottom-center{
        
        justify-content: flex-end;
        align-items: flex-end;
    
    }
 
}

@mixin container-row($align: $align-top-fill, $wrap: false) {

    display: flex;
    flex-direction: row;

    @if $wrap == true {
        flex-wrap: wrap;
    } @else {
        flex-wrap: nowrap;
    }
    
    @if $align == $align-top-fill {
    
        justify-content: flex-start;
        align-items: stretch;
    
    } @else if $align == $align-top-left{
        
        justify-content: flex-start;
        align-items: flex-start;
    
    } @else if $align == $align-top-center{
        
        justify-content: center;
        align-items: flex-start;
    
    } @else if $align == $align-top-right{
        
        justify-content: flex-end;
        align-items: flex-start;
    
    } @else if $align == $align-left{
        
        justify-content: flex-start;
        align-items: center;
    
    } @else if $align == $align-center{
        
        justify-content: center;
        align-items: center;
    
    } @else if $align == $align-right{
        
        justify-content: flex-end;
        align-items: center;
    
    } @else if $align == $align-bottom-left{
        
        justify-content: flex-start;
        align-items: flex-end;
    
    } @else if $align == $align-bottom-center{
        
        justify-content: center;
        align-items: flex-end;
    
    } @else if $align == $align-bottom-right{
        
        justify-content: flex-end;
        align-items: flex-end;
    
    }
 
}