@use 'sass:math';

@function convert-sp-to-rem($sp) {
    // Web browsers calculate the REM (the root em size) based on the root element size
    // The default for modern web browsers is 16px, so the conversion is SP_SIZE/16 = rem.
    @return (math.div($sp,16)) + rem;
}

$window-size-compact: 'compact';
$window-size-medium: 'medium';
$window-size-expanded: 'expanded';
$window-size-large: 'large';
$window-size-extra-large: 'extra-large';

@mixin for-size($window-size) {
    @if $window-size == $window-size-compact {
      @media (max-width: 599px) { @content; }
    } @else if $window-size == $window-size-medium {
      @media (min-width: 600px) and (max-width: 839px) { @content; }
    } @else if $window-size == $window-size-expanded {
      @media (min-width: 840px) and (max-width: 1199px) { @content; }
    } @else if $window-size == $window-size-large {
      @media (min-width: 1200px) and (max-width: 1599px) { @content; }
    } @else if $window-size == $window-size-extra-large {
      @media (min-width: 1600px) { @content; }
    }
}