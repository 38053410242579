@use '../../../styles' as styles;

.AppLayout{
    --lms-layout-padding-left-right: 24px;

    @include styles.lms-flex-container-row();

    height: 100%;

}


@include styles.lms-layout-compact(){
    
    .AppLayout {
        --lms-layout-padding-left-right: 16px;
    }

}

.AppLayout-content{
    @include styles.lms-flex-container-column();
    @include styles.lms-flex-item-auto();

}

.AppLayout-contentScrollArea{
    @include styles.lms-flex-container-column();
    @include styles.lms-flex-item-auto();

    overflow-y: auto;
    position: relative;
}

.AppLayout-topAppBar{
    background-color: var(--md-sys-color-background);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
}

.AppLayout-main{
    @include styles.lms-flex-container-column();
    @include styles.lms-flex-item-auto();
    background-color: var(--md-sys-color-background);
        
    padding-left: var(--lms-layout-padding-left-right);
    padding-right: var(--lms-layout-padding-left-right);

}

.AppLayout-navigationDrawer{

    width: 0;
    transition-duration: var(--md-sys-motion-duration-long2);
    transition-property: width;
    transition-timing-function: var(--md-sys-motion-easing-emphasized);

    &.is-opened{
        width: 360px;
    }

}

.AppLayout-navigationBar{
    @include styles.lms-flex-item-none();
}
    

    

//     &>.lms-app-layout_navigation-rail{
//         @include styles.lms-flex-item-none();
//     }

//     &>.lms-app-layout_navigation-drawer{
//         @include styles.lms-flex-item-none();
//     }

//     &>.lms-app-layout_content{
        

//         &>.lms-app-layout_top-app-bar{
            
//         }
    
//         &>.lms-app-layout_navigation-bar{
//             bottom: 0;
//         }

//         &>.lms-app-layout_main{
            
//         }

//     }

   

    // .lms-app-layout_main-container{
    //     @include styles.lms-flex-container-row();
    // }
// }
// nav.lms-app-layout_nav{
 
// }

// main.lms-app-layout_main{
//     @include styles.lms-flex-item-auto();

//     // overflow-y: auto;
// }