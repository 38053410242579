@use "sass:map";
@use "@material/web/typography/typeface";
@use "../tokens";

@mixin font-face{

    // @font-face {
    //     font-family: 'Roboto Flex';
    //     src: url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');
    // }
    
    @import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');

    @font-face {
        font-family: 'Material Symbols Outlined';
        font-style: normal;
        font-weight: 100 700;
        src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v156/kJEhBvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oFsI.woff2) format('woff2');
    }
}

@mixin theme {
    @include typeface.theme(tokens.lms-ref-typeface-values());
}