@use '../flex/flex.scss';


@mixin styles {
  
    html {
        font-size: 100%; // DO NOT CHANGE THIS VALUE (IT IS USED FOR REM CALCULATION)
        height: 100%;
        overflow: hidden;
    }

    body {
        background-color: var(--lms-sys-color-background);
        height: 100%;
        margin: 0;
        min-width: 300px
    }

    #root {
        @include flex.container-column();
        height: 100%;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    dl,
    dt,
    dd,
    p {
        margin: 0;
    }

    button{
        background-color: unset;
        border: 0;
        padding: 0;

        &:focus-visible{
            outline: none;
        }
    }
    input{
        background-color: unset;
        border: 0;

        &:focus-visible{
            outline: none;
        }
    }
  
    figure{
        margin: 0;
        
    }
    ul{
        padding: 0;
        margin: 0;
    }
    
    li{
        list-style: none;
    }
}