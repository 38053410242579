
@use '../../../../styles' as styles;

.ListDetailLayout {
    --lms-list-detail-layout-backdrop-position-left: 0;
    --lms-list-detail-layout-backdrop-position-bottom: 0;
    --lms-list-detail-layout-detail-pane-z-index: 0;
    --lms-list-detail-layout-list-pane-width: 360px;
    
    @include styles.lms-flex-container-gap(0, 24);

}


.ListDetailLayout{
    @include styles.lms-flex-item-auto();
    @include styles.lms-flex-container-row();

}

.ListDetailLayout-listPane{
    @include styles.lms-flex-item-auto();

    min-width: var(--lms-list-detail-layout-list-pane-width);
    max-width: var(--lms-list-detail-layout-list-pane-width);

}
.compact{
    overflow-y: scroll;

    

}

.ListDetailLayout-detailPane{
    @include styles.lms-flex-item-auto();
    @include styles.lms-flex-container-column();
    
    z-index: var(--lms-list-detail-layout-detail-pane-z-index);  

    &:not(.is-active){
        display: none;
    }

}

.ListDetailLayout-emptyDetailPane{
    @include styles.lms-flex-item-auto();
    @include styles.lms-flex-container-column();
    background-color: var(--md-sys-color-surface-container);
    &.is-active{
        display: none;
    }

}

.ListDetailLayout-background{
        
    background-color: var(--md-sys-color-scrim);
    position: fixed;
    top: 0;
    bottom: var(--lms-list-detail-layout-backdrop-position-bottom);
    left: var(--lms-list-detail-layout-backdrop-position-left);
    right: 0;
    display: none;
    opacity: 32%;
    z-index: 199;
}


@mixin compact-medium-styles (){

    .ListDetailLayout-background{
        @include styles.lms-animation-standard(){
            from{
                display: block;
                opacity: 32%;
            }
            to{
                opacity: 0;
                display: none;
            }
        }
        
        &.is-active{
            display: block;

            @include styles.lms-animation-standard(){
                from{
                    opacity: 0;
                }
                to{
                    opacity: 32%;
                }
            }
    
        }
    }

    .ListDetailLayout-detailPane{
                
        background-color: var(--md-sys-color-background);
        position: fixed;
        top: 0;
        bottom: var(--lms-list-detail-layout-backdrop-position-bottom);
        left: var(--lms-list-detail-layout-backdrop-position-left);
        right: 0;
        display: none;
        z-index: 200;

        @include styles.lms-animation-emphasized-accelerate(){
            0%{
                display: block;
                transform: translateX(0);
            }
            50%{
                opacity: 1;
            }
            100%{
                transform: translateX(30%);
                opacity: 0;
                display: none;
            }
        }

        &.is-active{

            display: block;

            @include styles.lms-animation-emphasized-decelerate(){
                0%{            
                    display: block;
                    transform: translateX(30%);
                    opacity: 0;
                }
                50%{
                    opacity: 1;
                }
                100%{
                    transform: translateX(0);
                }
            }

        }
    }

    .ListDetailLayout-emptyDetailPane{
        display: none;
    }
}

@include styles.lms-layout-compact(){
    
    .ListDetailLayout {
        --lms-list-detail-layout-backdrop-position-bottom: 80px;
        --lms-list-detail-layout-detail-pane-z-index: 200;  
        --lms-list-detail-layout-list-pane-width: auto;

        @include styles.lms-flex-container-gap(0, 16);

    }

    @include compact-medium-styles();
}

@include styles.lms-layout-medium(){

    .ListDetailLayout {      
        --lms-list-detail-layout-backdrop-position-left: 80px;
        --lms-list-detail-layout-detail-pane-z-index: 200;  
        --lms-list-detail-layout-list-pane-width: auto;

        @include styles.lms-flex-container-gap(0, 24);

    }

    @include compact-medium-styles();
}
