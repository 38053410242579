.Image {
    width: 100%;
    height: auto;
}

.Image--auto{

    &.is-landscape{
        aspect-ratio: auto;
    }

    &.is-portrait{
        aspect-ratio: auto;
    }
    
}

.Image--goldenRatio{

    &.is-landscape{
        aspect-ratio: 1.618 / 1;
    }

    &.is-portrait{
        aspect-ratio: 1 / 1.618;
    }
    
}

.Image--twoOne{

    &.is-landscape{
        aspect-ratio: 2 / 1;
    }

    &.is-portrait{
        aspect-ratio: 1 / 2;
    }
    
}