@use '../../../../styles';

.ProfileInfo {
    @include styles.lms-flex-container-column();
    @include styles.lms-flex-container-gap(16, 0);

    background-color: var(--md-sys-color-surface-container);
    border-radius: var(--md-sys-shape-corner-medium);
    padding: 16px;

    h2 {
        color: var(--md-sys-color-on-surface);
    }
    
    dl {
        @include styles.lms-flex-container-column();
        @include styles.lms-flex-container-gap(8, 0);
    }
}

.ProfileInfo-dataItem {
    @include styles.lms-flex-container-row();
    
    dt {
        min-width: 120px;
        color: var(--md-sys-color-on-surface-variant);
        margin-right: 5px;
    }

    dd {
        color: var(--md-sys-color-on-surface);
    }
}